import { useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';

import { PropertyImage } from '../../components/CustomImages';
import { getMatchedProperties } from '../../services/student';

export const loader = async ({ params }) => getMatchedProperties();

const Property = ({ property }) => {
  const navigate = useNavigate();

  return (
    <div style={styles.propertyContainer} onClick={() => navigate(`/property/${property._id}`)}>
      <PropertyImage image={property?.images?.at(0)?.image} style={styles.propertyImage} />
      <div style={styles.infoContainer}>
        <div style={styles.propertyName}>{property.name}</div>
        <div style={styles.smallText}>{property.details.beds === 'Studio' ? 'Studio' : `${property.details.beds} Beds`} | {property.details.baths} Bath{property.details.baths === 1 ? '' : 's'} {property.parking?.spots > 0 ? ' | Parking' : ''}</div>
      </div>
    </div>
  );
}

export default function Matches() {
  const { best, similar } = useLoaderData();

  const [bestProperties, setBestProperties] = useState(best);
  const [similarProperties, setSimilarProperties] = useState(similar);

  if ((!bestProperties || bestProperties.length === 0) && (!similarProperties || similarProperties.length === 0)) {
    return (
      <div className='page-container'>
        <div style={{ fontFamily: 'Maharlika' }}>
          Looks like there are not any available matches for your criteria. Try <Link to='/match' style={{ color: 'black' }}>adjusting your criteria</Link> or check back later as we are always checking for new property matches!
        </div>
      </div>
    );
  }
  return (
    <div className='page-container'>
      {bestProperties.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={styles.title}>Best Matches</div>
          <hr />
        </div>
      )}
      <div className='matched-view'>
        {bestProperties.map((property, idx) => (
          <div key={property._id}>
            <Property property={property} />
          </div>
        ))}
      </div>

      {similarProperties.length > 0 && (
        <div style={{ marginBottom: 24, marginTop: bestProperties.length > 0 ? 40 : 0 }}>
          <div style={styles.title}>Similar Matches</div>
          <hr />
        </div>
      )}
      <div className='matched-view'>
        {similarProperties.map((property, idx) => (
          <div key={property._id}>
            <Property property={property} />
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  propertyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    fontFamily: 'Maharlika',
    cursor: 'pointer',
  },
  propertyImage: {
    width: 'auto',
    height: '60vh',
    aspectRatio: 5 / 7,
  },
  infoContainer: {
    flexDirection: 'column',
    paddingBottom: 12,
  },
  propertyName: {
    fontSize: 16,
    marginBottom: 4,
  },
  smallText: {
    fontSize: 12,
    color: '#46483E',
  },
  title: {
    fontFamily: 'Maharlika',
    fontSize: 20,
  },
};
