import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, createSearchParams, useLocation } from 'react-router-dom';

import { AuthContext } from '../../components/AuthContext';
import { authenticate } from '../../constants/helpers';

import Colors from '../../constants/Colors';

const TABS = [
  {
    title: 'My Matches',
    path: 'matches',
  },
  {
    title: 'My Account',
    path: 'account',
  },
];

export default function Student() {
  const { state, authContext } = useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = useState(TABS.find(tab => tab.path.toLowerCase() === location.pathname?.split('/')?.pop()?.toLowerCase()) || TABS[0]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!authenticate(state.user, 'student')) {
      navigate({
        pathname: '/login',
        search: createSearchParams({
          type: 'student'
        }).toString()
      });
    } else {
      setLoading(false);
    }
  }, [state, navigate]);

  useEffect(() => {
    setPage(TABS.find(tab => tab.path.toLowerCase() === location.pathname?.split('/')?.pop()?.toLowerCase()) || TABS[0]);
  }, [location]);

  const handleLogout = () => {
    const confirm = window.confirm('Are you sure you want to log out?');
    
    if (confirm) {
      authContext.signOut();
      navigate('/');
    }
  }

  const MenuItem = ({ title, path }) => {
    return (
      <Link to={`/student/${path}`} className='link'>
        <div className={`sidenav-item ${path === page.path ? 'selected' : ''}`}>
          <div>{title}</div>
        </div>
      </Link>
    );
  }

  return (
    <div style={styles.container}>
      {/* <LoadingOverlay loading={isLoading} /> */}

      <div className='sidenav'>
        <div style={styles.companyName}>{state.user?.first_name}</div>
        {TABS.map((value, idx) => (
          <MenuItem key={idx} title={value.title} path={value.path} />
        ))}
        <div className='sidenav-item' onClick={handleLogout}>
          <div>Log Out</div>
        </div>
      </div>

      <div id='sidenav-content'>
        <Outlet />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: Colors.light.background,
  },
  content: {
    flex: 1,
    padding: 24,
    marginLeft: 200,
    backgroundColor: Colors.light.background,
  },
  navItem: {
    height: 50,
    lineHeight: 40,
    paddingLeft: 12,
    justifyContent: 'center'
  },
  item: {
    color: '#808080',
    fontSize: 20,
  },
  itemActive: {
    color: 'black',
    fontSize: 20,
  },
  companyName: {
    fontSize: 24,
    paddingBottom: 24,
    fontFamily: 'Maharlika',
  },
  logoutButton: {
    marginTop: 20,
    paddingLeft: 12,
  },
  logout: {
    color: '#808080',
    fontSize: 20,
  },
  logoutActive: {
    color: 'black',
    fontSize: 20,
  },
  icon: {
    width: 100,
    height: 100,
    aspectRatio: 1 / 1,
    marginBottom: 24,
  },
  menuItemActive: {
    backgroundColor: Colors.light.background,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
};
