import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Root, { loader as rootLoader, action as rootAction, loader as contactLoader } from './routes/root';
import ErrorPage from './404';
import Home from './routes/home';
import Match, { loader as quizLoader } from './routes/match';
import Contact from './routes/contact';
import Login from './routes/login';
import Student from './routes/student/home';
import Matches, { loader as matchesLoader } from './routes/student/matches';
import StudentSettings, { loader as schoolLoader } from './routes/student/settings';
import Landlord from './routes/landlord/home';
import Properties, { loader as propertiesLoader } from './routes/landlord/properties';
import Settings, { loader as settingsLoader } from './routes/landlord/settings';
import Billing from './routes/landlord/billing';
import LandlordProperty, { loader as landlordPropertyLoader } from './routes/landlord/property';
import Messages, { loader as messageLoader } from './routes/landlord/messages';
import VerifyEmail from './routes/verifyEmail';
import Property, { loader as propertyLoader} from './routes/property';

import TermsOfService from './routes/terms';
import PrivacyPolicy from './routes/privacy';

import { AuthProvider } from './components/AuthContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    // loader: rootLoader,
    // action: rootAction,
    children: [
      {
        path: '',
        element: <Home />,
        // loader: contactLoader,
      },
      {
        path: 'match',
        element: <Match />,
        loader: quizLoader,
      },
      {
        path: 'contact',
        element: <Contact />,
        // loader: contactLoader,
      },
      {
        path: 'login',
        element: <Login />,
        // loader: contactLoader,
      },
      {
        path: 'student',
        element: <Student />,
        // loader: contactLoader,
        children: [
          {
            index: true,
            element: <Matches />,
            loader: matchesLoader,
          },
          {
            path: 'matches',
            element: <Matches />,
            loader: matchesLoader,
          },
          {
            path: 'account',
            element: <StudentSettings />,
            loader: schoolLoader,
          },
        ],
      },
      {
        path: 'landlord',
        element: <Landlord />,
        // loader: contactLoader,
        children: [
          {
            index: true,
            element: <Properties />,
            loader: propertiesLoader,
          },
          {
            path: 'properties',
            element: <Properties />,
            loader: propertiesLoader,
          },
          {
            path: 'account',
            element: <Settings />,
            loader: settingsLoader,
          },
          {
            path: 'billing',
            element: <Billing />,
            // loader: contactLoader,
          },
          {
            path: 'messages',
            element: <Messages />,
            loader: messageLoader,
          },
        ],
      },
      {
        path: 'landlord/property/:propertyId',
        element: <LandlordProperty />,
        loader: landlordPropertyLoader,
      },
      {
        path: 'verifyemail',
        element: <VerifyEmail />,
      },
      {
        path: 'property/:propertyId',
        element: <Property />,
        loader: propertyLoader,
      },
      {
        path: '/terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
