import { useState } from 'react';

import { validateEmail } from '../constants/helpers';
import { contactSupport } from '../services/contact';

export default function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [bodyError, setBodyError] = useState(false);

  const submitForm = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      alert('Please fill out all fields');
      return;
    }

    if (!subject || subject.trim().length < 3) {
      setSubjectError(true);
      alert('Please fill out all fields');
      return;
    }

    if (!body || body.trim().length < 50) {
      setBodyError(true);
      alert('Please fill out all fields. Body must be at least 50 characters');
      return;
    }

    setEmailError(false);
    setSubjectError(false);
    setBodyError(false);

    contactSupport(email, subject, body)
      .then((success) => {
        if (success) {
          setEmail('');
          setSubject('');
          setBody('');
          alert('Message sent!');
        }
      })
      .then(null, (err) => console.log(err));
  }

  return (
    <div style={styles.container}>
      <div source={require('../assets/images/contactImage.png')} style={styles.headerImage} />

      <div style={styles.contactContainer}>
        <div style={styles.title}>Contact Us</div>

        <input
          autoCapitalize='words'
          autoCorrect='false'
          className='text-input'
          onChange={({ target }) => setEmail(target.value)}
          // onSubmitEditing={() => this.passwordInput.focus()}
          placeholder='Email*'
          type='text'
          style={styles.input}
          value={email}
        />

        <input
          autoCapitalize='words'
          autoCorrect='false'
          className='text-input'
          onChange={({ target }) => setSubject(target.value)}
          // onSubmitEditing={() => this.passwordInput.focus()}
          placeholder='Subject*'
          type='text'
          style={styles.input}
          value={subject}
        />

        <textarea
          autoCapitalize='words'
          autoCorrect='false'
          onChange={({ target }) => setBody(target.value)}
          // onSubmitEditing={() => this.passwordInput.focus()}
          placeholder='Body*'
          style={styles.textarea}
          value={body}
        ></textarea>
        <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, marginBottom: 24 }}>
          min: {body.length > 2000 ? 2000 : body.length}/50 characters
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className='solid-button' onClick={submitForm}>Submit</button>
        </div>

        <div style={styles.hr}></div>

        {/* <View style={styles.socialRow}>
          <CustomText style={styles.title}>Socials</CustomText>
          <View style={styles.socialIcons}>
            <Entypo name='facebook' size={24} color='#8D7A6F' />
            <FontAwesome name="twitter-square" size={24} color='#8D7A6F' />
            <FontAwesome5 name="instagram-square" size={24} color='#8D7A6F' />
          </View>
        </View> */}

      </div>
    </div >
  );
}

const styles = {
  container: {
    flex: 1,
  },
  headerImage: {
    width: '100%',
    height: '40%',
  },
  contactContainer: {
    margin: 40,
  },
  contactRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  socialRow: {
    flexDirection: 'row',
    gap: 100,
    paddingLeft: 20,
    paddingRight: 20,
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 1,
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 20,
    color: '#8D7A6F',
    fontFamily: 'Maharlika',
    marginBottom: 24,
  },
  info: {
    color: '#8D7A6F',
  },
  socialIcons: {
    flexDirection: 'row',
    gap: 24,
  },
  input: {
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    height: 30,
    outlineStyle: 'none',
    width: '100%',
    marginBottom: 20,
  },
  textarea: {
    minHeight: 100,
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
};
