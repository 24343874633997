export async function getItem(item) {
  const value = await window.localStorage.getItem(item);
  return value ? JSON.parse(value) : null;
}

export async function setItem(item, value) {
  return window.localStorage.setItem(item, JSON.stringify(value));
}

export async function removeItem(item) {
  return window.localStorage.removeItem(item);
}
