import { getItem } from '../components/storage';
import Globals from '../constants/Globals';

const getAuthToken = () => getItem('userToken');

export const contactSupport = async (email, subject, body) => {
  return fetch(`${Globals.ROOT_URL}/api/contact/support`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      subject,
      body,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}
