import { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLoaderData } from 'react-router-dom';

import { AuthContext } from '../components/AuthContext';
import { ImageScroller2 } from '../components/CustomImages';
import { getPropertyStudent, sendMessage } from '../services/student';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export const loader = async ({ params }) => getPropertyStudent(params.propertyId);

export default function Property() {
  const { state } = useContext(AuthContext);
  const messageRef = useRef();
  const navigate = useNavigate();

  const propertyData = useLoaderData();

  const [property, setProperty] = useState(propertyData);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!property.active) setError(true);
  }, [property]);

  const formatDate = (date) => new Date(date).toLocaleDateString('en-US', {
    year: 'numeric', month: 'short', day: 'numeric',
  });

  const emailLandlord = () => {
    const message = messageRef.current?.value;

    if (message) {
      if (message.length > 50) {
        sendMessage(message, property._id, property.landlord._id)
          .then((success) => {
            if (success) {
              alert('Message sent!');
              setOpen(false);
            }
          })
          .then(null, (err) => console.log(err));
      } else {
        alert(`Message must be at least 50 characters: ${message.length}/50`)
      }
    }
  }

  const MessageContent = () => (
    <div>
      <div style={{ fontFamily: 'Maharlika' }}>Message</div>
      <textarea ref={messageRef} style={{ width: '100%', maxWidth: '100%', minWidth: '100%', marginTop: 8, marginBottom: 2 }} placeholder='Enter message...' maxLength={2000} required></textarea>
      <div style={{ fontSize: 10, lineHeight: '12px', display: 'flex', justifyContent: 'flex-end' }}>min: 50 characters</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
        <button className='clear-button' onClick={emailLandlord}>Send Message</button>
      </div>
    </div>
  );

  if (error) {
    return (
      <div id='error-page'>
        <h1>Oops!</h1>
        <p>We cannot find this listing. It may have been removed by the landlord.</p>
        <button className='solid-button' onClick={() => navigate(-1)}>Go Back</button>
      </div>
    )
  }

  return (
    <div className='page-container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className='property-page-container'>
        <div className='scroll-image-container'>
          <ImageScroller2 images={property.images} style={styles.propertyImage} />
        </div>

        <div style={styles.infoContainer}>
          <div style={{ fontSize: 24 }}>{property.name}</div>

          <div style={styles.title}>Address</div>
          <div>{property.address?.line1}, {property.address?.line2 ? `${property.address.line2}, ` : ''} {property.address?.city}, {property.address?.state} {property.address?.zip}</div>

          <div style={styles.title}>Details</div>
          <div>{property.details.beds === 'Studio' ? 'Studio' : `${property.details.beds} Bedroom${property.details.beds === 1 ? '' : 's'}`}</div>
          <div>{property.details?.baths || 0} Bathroom{property.details.baths === 1 ? '' : 's'}</div>
          {property.parking?.spots > 0 && <div>Parking</div>}

          <div style={styles.title}>Lease Information</div>
          <div>Available: {formatDate(property.available)}</div>
          <div>Application Fee: {property.application_fee > 0 ? `$${property.application_fee.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : 'N/A'}</div>
          {property.pets?.allowed && (
            <div>Pet Fees: Deposit -- {property.pets.deposit > 0 ? `$${property.pets.deposit.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : 'N/A'} / Monthly -- {property.pets.monthly > 0 ? `$${property.pets.monthly.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : 'N/A'}</div>
          )}
          <div>Montly Rent: {`$${property.rent.toLocaleString('en-US', { maximumFractionDigits: 0 })}`}</div>
          <div>Security Deposit: {`$${property.security_deposit.toLocaleString('en-US', { maximumFractionDigits: 0 })}`}</div>
          {property.renters_insurance && <div>Renter's Insurance Required</div>}
          {property.personal_guarantor && <div>Personal Guarantor Required</div>}

          <div style={styles.landlordContainer}>
            <div style={styles.title}>Landlord</div>
            <div>{property.landlord?.company_name}</div>
            {state.user && (
              <div style={{ marginTop: 12 }}>
                <Popup
                  trigger={<button className='solid-button'>Contact Landlord</button>}
                  position='center center'
                  modal={true}
                  closeOnEscape={false}
                  contentStyle={{ padding: 16, maxHeight: '90%' }}
                  open={modalOpen}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                >
                  <MessageContent />
                </Popup>
              </div>
            )}
            {!state.user && (
              <div style={{ marginTop: 12 }}>
                <div style={{ fontSize: 14 }}>Please <Link to='/login?type=student' style={{ color: 'black' }}>login</Link> to<br/>contact landlord.</div>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className='property-page-container' style={{ marginTop: 48 }}>
        <div style={styles.descriptionContainer}>
          <div style={styles.title}>Description</div>
          <div style={styles.description}>{property.description}</div>
        </div>

        <div>
          <div style={styles.title}>Appliances</div>
          <div>
            {property.appliances?.fridge && <div>Refridgerator</div>}
            {property.appliances?.garbage_disposal && <div>Garbage Disposal</div>}
            {property.appliances?.dishwasher && <div>Dishwasher</div>}
            {property.appliances?.stove && <div style={{ textTransform: 'capitalize' }}>{property.appliances.stove_type} Stove</div>}
            {property.appliances?.washing_machine && <div>Washing Machine</div>}
            {property.appliances?.dryer && <div>Dryer</div>}
            {property.appliances?.wine_cooler && <div>Wine Cooler</div>}
            {property.appliances?.microwave && <div>Microwave</div>}
          </div>

          <div style={styles.title}>Heating/Cooling</div>
          <div>
            <div>Heating Type: {property.heat.unit}</div>
            <div>Heating Source: {property.heat.source}</div>
            <div>A/C: {property.ac}</div>
          </div>

          {property.flooring?.length > 0 && (
            <div>
              <div style={styles.title}>Flooring</div>
              {property.flooring.sort().map((value, idx) => (
                <div key={idx}>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <div style={styles.title}>Parking</div>
          <div>
            <div>Spots: {property.parking.spots}</div>
            {property.parking.fee > 0 && <div>Fee: {`$${property.parking.fee.toLocaleString('en-US', { maximumFractionDigits: 2 })}`}</div>}
            {property.parking.driveway && <div>Driveway</div>}
            {property.parking.garage && <div>Garage</div>}
            {property.parking.street && <div>Street</div>}
          </div>

          {property.hasIncludedUtilities && (
            <>
              <div style={styles.title}>Utilities Included</div>
              <div>
                {property.utilities?.electric && <div>Electric</div>}
                {property.utilities?.gas && <div>Gas/Oil</div>}
                {property.utilities?.homeSecurity && <div>Home Security</div>}
                {property.utilities?.landscaping && <div>Landscaping</div>}
                {property.utilities?.pestControl && <div>Pest Control</div>}
                {property.utilities?.recycle && <div>Recycle</div>}
                {property.utilities?.snowRemoval && <div>Snow Removal</div>}
                {property.utilities?.trash && <div>Trash</div>}
                {property.utilities?.water && <div>Water/Sewer</div>}
                {property.utilities?.wifi && <div>Wifi</div>}
              </div>
            </>
          )}

          {property.hasNonIncludedUtilities && (
            <>
              <div style={styles.title}>Utilities Not Included</div>
              <div>
                {!property.utilities?.electric && <div>Electric</div>}
                {!property.utilities?.gas && <div>Gas/Oil</div>}
                {!property.utilities?.homeSecurity && <div>Home Security</div>}
                {!property.utilities?.landscaping && <div>Landscaping</div>}
                {!property.utilities?.pestControl && <div>Pest Control</div>}
                {!property.utilities?.recycle && <div>Recycle</div>}
                {!property.utilities?.snowRemoval && <div>Snow Removal</div>}
                {!property.utilities?.trash && <div>Trash</div>}
                {!property.utilities?.water && <div>Water/Sewer</div>}
                {!property.utilities?.wifi && <div>Wifi</div>}
              </div>
            </>
          )}
        </div>
      </div>
    </div >
  );
}

const styles = {
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    flexWrap: 'wrap',
  },
  propertyImage: {
    width: 'auto',
    height: '80%',
    aspectRatio: 5 / 7,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 12,
    flex: 1,
    fontFamily: 'Maharlika',
  },
  propertyName: {
    fontSize: 16,
    marginBottom: 4,
  },
  smallText: {
    fontSize: 12,
    color: '#46483E',
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 1,
    marginTop: 12,
    marginBottom: 12,
  },
  input: {
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    height: 30,
    outlineStyle: 'none',
    width: '100%',
    fontFamily: 'Maharlika',
  },
  label: {
    fontSize: 10,
  },
  title: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 8,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 24,
  },
  button: {
    display: 'flex',
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  buttonSave: {
    backgroundColor: '#CCBFB9',
  },
  buttonSaveActive: {
    backgroundColor: '#B5AAA5',
  },
  buttonText: {
    color: '#F4F3F1',
  },
  buttonDelete: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  buttonDeleteActive: {
    backgroundColor: '#B3735A',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  deleteText: {
    color: '#46483E',
  },
  landlordContainer: {
    marginTop: 0,
  },
  link: {
    textDecorationLine: 'underline',
    color: 'black',
  },
  linkActive: {
    textDecorationLine: 'underline',
    color: '#767676',
  },
  descriptionContainer: {
    width: 'calc(75vh * 5 / 7)',
  },
  description: {
    lineHeight: '18px',
  },
};
