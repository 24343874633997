// import { Dimensions, Image, ImageBackground, Pressable, StyleSheet, Text, TextInput, View } from 'react-native';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContext } from '../components/AuthContext';

import { getItem, removeItem } from '../components/storage';
import { validateEmail } from '../constants/helpers';
import Colors from '../constants/Colors';

const LoginForm = ({ email, password, setEmail, setPassword, error, login, accountType }) => {
  return (
    <>
      <div style={styles.header}>{accountType} Login</div>
      <div style={styles.error}>{error}</div>
      <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({target}) => setEmail(target.value)}
        // onSubmitEditing={() => this.passwordInput.focus()}
        placeholder='Email'
        type='text'
        style={{ marginBottom: 16 }}
        value={email}
      />

      <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({ target }) => setPassword(target.value)}
        // onSubmitEditing={() => this.passwordInput.focus()}
        placeholder='Password'
        type='password'
        style={{ marginBottom: 36 }}
        value={password}
      />

      <button className='solid-button' onClick={login}>Login</button>
    </>
  );
}

const Signup = ({ name, companyName, email, password, passwordVerify, setName, setCompanyName, setEmail, setPassword, setPasswordVerify, error, signup, accountType, agreed, setAgreed }) => {

  return (
    <>
      <div style={styles.header}>{accountType} Sign Up</div>
      <div style={styles.error}>{error}</div>
      {accountType === 'landlord' && (
        <input
          autoCapitalize='none'
          autoCorrect='false'
          className='text-input'
          onChange={({ target }) => setCompanyName(target.value)}
          placeholder='Company Name'
          type='text'
          style={styles.input}
          value={companyName}
        />
      )}

      <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({ target }) => setName(target.value)}
        placeholder='Full Name'
        type='text'
        style={styles.input}
        value={name}
      />

      <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({ target }) => setEmail(target.value)}
        placeholder='Email'
        type='text'
        style={styles.input}
        value={email}
      />

       <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({ target }) => setPassword(target.value)}
        placeholder='Password'
        type='password'
        style={styles.input}
        value={password}
      />

       <input
        autoCapitalize='none'
        autoCorrect='false'
        className='text-input'
        onChange={({ target }) => setPasswordVerify(target.value)}
        placeholder='Verify Password'
        type='password'
        style={{ ...styles.input, marginBottom: 4 }}
        value={passwordVerify}
      />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 20, justifyContent: 'flex-start', width: '100%' }}>
        <input
          type='checkbox'
          checked={agreed}
          onChange={({ target }) => setAgreed(target.checked)}
        />
        <div style={{ fontSize: 10, transform: 'translateY(2px)', width: '80%' }}>By checking this box you agree to our <Link to='/terms-of-service' target='_blank' style={{ color: '#4C4E52', textDecoration: 'none', fontWeight: 'bold' }}>Terms of Service</Link> and <Link to='/privacy-policy' target='_blank' style={{ color: '#4C4E52', textDecoration: 'none', fontWeight: 'bold' }}>Privacy Policy</Link>.</div>

      </div>

      <button className='solid-button' onClick={signup}>Create Account</button>
    </>
  );
}

export default function Login() {
  const { state, authContext } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const [isLogin, setLogin] = useState(true);
  const [userType, setUserType] = useState(params.get('type') || 'student');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (state.user) {
      if (!state.user.verified_email) navigate('/verifyemail');
      else {
        getItem('loginRedirect')
          .then((redirect) => {
            removeItem('loginRedirect');

            if (redirect) navigate(redirect);
            else {
              if (state.user.type === 'landlord') navigate('/landlord');
              else navigate('/student');
            }
          })
          .then(null, () => {
            if (state.user.type === 'landlord') navigate('/landlord');
            else navigate('/student');
          });
      }
    }
  }, [state, navigate]);

  useEffect(() => {
    if (state.errorMessage) setError(state.errorMessage);
    else setError('');
  }, [state]);

  const login = async () => {
    if (email?.trim()?.length < 1) {
      setError('Enter a valid email address');
      return;
    }
    if (password.length < 8) {
      setError('Password must have a minimum of 8 characters.');
    }

    authContext.signIn({ email: email.trim(), password, type: userType });
  }

  const signup = () => {
    if (userType === 'landlord' && companyName?.trim()?.length < 1) {
      setError('Enter a valid company name');
      return;
    } else if (name?.trim()?.length < 1) {
      setError('Enter a valid name');
      return;
    }
    if (!validateEmail(email)) {
      setError('Enter a valid email address');
      return;
    }
    if (password.length < 8) {
      setError('Password must have a minimum of 8 characters');
      return;
    }

    if (password !== passwordVerify) {
      setError('Passwords do not match');
      return;
    }

    if (!agreed) {
      setError('Please agree to our Terms and Privacy Policy to continue');
      return;
    }

    authContext.signUp({ company_name: companyName.trim(), email: email.trim(), password, type: userType, full_name: name.trim() });
  }

  if (isMobile) {
    return (
      <div className='section-full-page home-background'>
        <div style={styles.container}>
          <div style={styles.contentMobile}>
            Please visit the desktop site to login to your account.

            <button className='solid-button' onClick={() => navigate('/')} style={{ marginTop: 36 }}>Home</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='section-full-page home-background'>
      <div style={styles.container}>
        <div style={styles.content}>
          {isLogin && (
            <LoginForm
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              error={error}
              login={login}
              accountType={userType}
            />
          )}

          {!isLogin && (
            <Signup
              name={name}
              companyName={companyName}
              email={email}
              password={password}
              passwordVerify={passwordVerify}
              setName={setName}
              setCompanyName={setCompanyName}
              setEmail={setEmail}
              setPassword={setPassword}
              setPasswordVerify={setPasswordVerify}
              error={error}
              signup={signup}
              accountType={userType}
              agreed={agreed}
              setAgreed={setAgreed}
            />
          )}

          <div style={styles.changeLoginContainer}>
            <div style={styles.loginTypeButton} onClick={() => setLogin(!isLogin)}>{isLogin ? 'Sign up' : 'Login'}</div>

            <div style={{ color: '#808080' }}> / </div>

            {userType === 'landlord' && (
              <div style={styles.loginTypeButton} onClick={() => setUserType('student')}>I'm a student</div>
            )}
            {userType === 'student' && (
              <div style={styles.loginTypeButton} onClick={() => setUserType('landlord')}>I'm a landlord</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft:70,
    paddingRight: 70,
    paddingTop: 50,
    paddingBottom: 20,
    backgroundColor: Colors.light.background,
    flexDirection: 'column',
    maxWidth: 500,
    width: '90%',
  },
  contentMobile: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: Colors.light.background,
    flexDirection: 'column',
    width: '90%',
    textAlign: 'center',
    fontFamily: 'Maharlika',
  },
  header: {
    fontSize: 20,
    marginBottom: 12,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontFamily: 'Maharlika',
  },
  input: {
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    height: 30,
    outlineStyle: 'none',
    width: '100%',
    marginBottom: 20,
  },
  loginButton: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  button: {
    backgroundColor: '#CCBFB9',
  },
  buttonActive: {
    backgroundColor: '#B5AAA5',
  },
  changeLoginContainer: {
    marginTop: 40,
    display: 'flex',
    gap: 6,
    flexDirection: 'row',
  },
  loginTypeButton: {
    color: '#808080',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  loginTypeButtonActive: {
    color: 'black',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: 12,
    minHeight: 20,
    width: '80%',
    textAlign: 'center',
  },
};
