import { Fragment, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { DefaultLoader } from '../../components/Loader';
import { PropertyImage } from '../../components/CustomImages';
import { getLandlordProperties, newProperty, publishProperty } from '../../services/landlord';

import check from '../../assets/svgs/check.svg';

export const loader = async ({ params }) => getLandlordProperties();

export default function Properties() {
  const navigate = useNavigate();
  const propertiesData = useLoaderData();

  const [properties, setProperties] = useState(propertiesData);
  const [isLoading, setLoading] = useState(false);

  const loadProperties = () => {
    setLoading(true);
    getLandlordProperties()
      .then((properties) => {
        setLoading(false);
        setProperties(properties);
      })
      .then(null, (err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const handlePublishClick = (property) => {
    publishProperty(property._id)
      .then(({ success, error }) => {
        if (success) {
          loadProperties();
          alert('Publish successful!');
        } else {
          if (error === 'Needs information') alert('Failed to publish. Please make sure all required information is correctly filled out.');
        }
      })
      .then(null, (err) => console.log(err));
  }

  const fullAddress = (address) => {
    if (address.line1 && address.city && address.state && address.zip) return `${address.line1}, ${address.line2 ? `${address.line2}, ` : ''} ${address.city}, ${address.state} ${address.zip}`;

    return '';
  }

  const addProperty = () => {
    newProperty()
      .then((property) => {
        navigate(`/landlord/property/${property._id}`)
      })
      .then(null, (err) => console.log(err));
  }

  const Property = ({ property }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={styles.propertyContainer} onClick={() => navigate(`/landlord/property/${property._id}`)}>
          <PropertyImage image={property?.images?.at(0)?.image} style={styles.propertyImage} />
          <div style={styles.infoContainer}>
            <div style={styles.propertyName}>{property.name}</div>
            <div style={styles.smallText}>{fullAddress(property.address)}</div>
            <div style={styles.smallText}>{property.details.beds === 'Studio' ? 'Studio' : `${property.details.beds} Beds`} | {property.details.baths} Baths{property.parking?.spots ? ' | Parking' : ''}</div>
          </div>
        </div>

        <div>
          {property.active && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
              <img src={check} style={{ width: 24, height: 24 }} alt='Check' />
              <div className='publish-status'>Published</div>
            </div>
          )}

          {!property.active && property.can_publish && (
            <button className='solid-button' onClick={() => handlePublishClick(property)}>Ready to Publish</button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      {isLoading && <DefaultLoader />}
      {properties.map((property, idx) => (
        <Fragment key={property._id}>
          <Property property={property} />
          <hr />
        </Fragment>
      ))}
      <button className='solid-button' onClick={addProperty}>New Property</button>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    padding: 24,
  },
  propertyContainer: {
    display: 'flex',
    padding: 12,
    flexDirection: 'row',
    gap: 24,
    cursor: 'pointer',
  },
  propertyImage: {
    width: 100,
    height: 140,
    aspectRatio: 5 / 7,
  },
  infoContainer: {
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 12,
  },
  propertyName: {
    fontSize: 16,
    marginBottom: 4,
  },
  smallText: {
    fontSize: 12,
    color: '#46483E',
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 4,
    marginTop: 12,
    marginBottom: 12,
  },
  button: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    alignSelf: 'flex-start',
  },
  buttonNew: {
    backgroundColor: '#CCBFB9',
  },
  buttonNewActive: {
    backgroundColor: '#B5AAA5',
  },
  buttonText: {
    color: '#F4F3F1',
  },
};
