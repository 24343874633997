import { useContext, useReducer } from 'react';
import { useLoaderData } from 'react-router-dom';
import Select from 'react-select';

import { Prompt } from '../../components/Prompt';
import { AuthContext } from '../../components/AuthContext';
import { userReducer } from '../../reducers/student';
import { getActiveSchools } from '../../services/student';

import info from '../../assets/svgs/info.svg';

export const loader = async ({ params }) => getActiveSchools();

export default function Settings() {
  const { state, authContext } = useContext(AuthContext);

  const [user, dispatchUser] = useReducer(userReducer, state.user);
  const { schools } = useLoaderData();

  const saveInformation = () => {
    authContext.updateUser(user, state);
    dispatchUser({ type: 'SAVED_CHANGES' });
    alert('Success!');
  }

  const handlePhone = (text) => {
    if (!(/^\d+$/.test(text.slice(-1)))) return;
    if (text.length > 10) return;

    dispatchUser({ type: 'PHONE', value: text });
  }

  const handleVerifyEmail = () => {
    window.alert('Your email is not verified. We have resent a verification email.');
    authContext.resendEmailVerification(state);
  }

  return (
    <div style={styles.container}>
      <Prompt
        when={user.madeChanges}
        message='You may have unsaved changes. Are you sure to discard them and leave this page?'
      />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <div className='info-header'>Account Information</div>
        {user.madeChanges && (
          <button className='solid-button' onClick={saveInformation}>Save</button>
        )}
      </div>
      <hr />

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Name</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            className='text-input'
            onChange={({ target }) => dispatchUser({ type: 'NAME', value: target.value })}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Full Name'
            type='text'
            style={styles.input}
            value={user.full_name || ''}
          />
        </div>
      </div>

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Email</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            className='text-input'
            disabled
            onChange={({ target }) => dispatchUser({ type: 'EMAIL', value: target.value })}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Account Email'
            type='text'
            style={styles.input}
            value={user.email || ''}
          />
        </div>
        {/* {!state.user.verified_email && <img className='unverified-email' src={info} style={{ width: 24, height: 24 }} alt='Unverified' onClick={handleVerifyEmail}/>} */}
      </div>

      {/* <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Phone</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            onChange={({ target }) => handlePhone(target.value)}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Account Phone'
            type='text'
            style={styles.input}
            value={user.phone || ''}
          />
        </div>
      </div> */}

      {/* <div className='info-table' style={{ transform: 'translateY(-4px)' }}>
        <div style={styles.column1}>
          <div style={styles.titleText}>School</div>
        </div>
        <div style={styles.column2}>
          <Select
            value={user.school ? { value: user.school, label: user.school } : null}
            onChange={({ value }) => dispatchUser({ type: 'SCHOOL', value })}
            options={schools.map((option) => ({
              value: option,
              label: option,
            }))}
            placeholder={'Select School...'}
            styles={selectStyles}
            maxMenuHeight={250}
          />
        </div>
      </div> */}
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    padding: 24,
  },
  header: {
    fontSize: 20,
    fontWeight: '500',
  },
  table: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  column1: {
    width: 140,
  },
  column2: {
    flex: 1,
    minWidth: 300,
    maxWidth: 500,
  },
  titleText: {
    fontSize: 14,
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 2,
    marginTop: 12,
    marginBottom: 24,
  },
  button: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  buttonAboutUs: {
    backgroundColor: '#CCBFB9',
  },
  buttonAboutUsActive: {
    backgroundColor: '#B5AAA5',
  },
  aboutText: {
    color: '#F4F3F1',
  },
  icon: {
    width: 150,
    height: 150,
    aspectRatio: 1 / 1,
  },
  iconContainer: {
    alignItems: 'center',
    gap: 12,
  },
};

const selectStyles = {
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    backgroundColor: 'transparent',
    // zIndex: 100,
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderRadius: 0,
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? '#D3D3D3' : 'white',
    color: 'black',
  }),
};
