export default function PrivacyPolicy() {
  return (
    <div className='page-container'>
      <div style={{ textAlign: 'center', margin: '32px 0' }}>
        <h1 style={{ margin: 0 }}>Our Privacy Commitment</h1>
        <div style={{ fontSize: 20, margin: 0 }}>Updated August 29, 2023</div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
        <div style={{ maxWidth: 1000 }}>

          <p>
            Thank you for choosing to be part of our community at Conessi, LLC (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at 8 The Green STE A, Dover, Delaware 19901 USA.
          </p>

          <p>
            When you visit our website and/or mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Apps and our services.
          </p>

          <p>
            This privacy policy applies to all information collected through our web or mobile application, ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").
          </p>

          <p>
            <b>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</b>
          </p>

          <p>
            <b>Changes to this Privacy Policy</b>
          </p>

          <p>
            The Company may change this Privacy Policy from time to time to accurately reflect how we gather and manage information about our users.
          </p>

          <p>
            We will post any changes to the Privacy Policy on the Sites. All changes to this Privacy Policy will be effective upon posting to the Sites. Please be sure to regularly check this page before using the Sites. Continued use of the Sites shall indicate your acceptance to any changes to this Privacy Policy.
          </p>

          <p>
            <b>Information We Collect</b>
          </p>

          <p>
            <b>I.	Personally Identifiable Information ("PII")</b>
          </p>
          <p>
            In Short: We collect personal information that you provide to us such as name, contact information, location, passwords and security data. We collect personal information that you voluntarily provide to us when registering at the Sites, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites or otherwise contacting us.
          </p>
          <p>
            The personal information that we collect depends on the context of your interactions with us and the Sites, the choices you make and the products and features you use. The personal information we collect can include the following:
          </p>

          <p>
            <b>Name and Contact Data.</b> We collect your first and last name, email address, phone number, and other similar contact data.
          </p>

          <p>
            <b>Credentials.</b> We collect passwords, password hints, and similar security information used for authentication and account access.
          </p>

          <p>
            <b>Location.</b> Depending on your use of the Sites, we may collect your approximate location and geographic information as determined through data such as GPS, IP address and WiFi.
          </p>

          <p>
            All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
          </p>

          <p>
            <b>II.	Non-Personally Identifiable Information</b>
          </p>

          <p>
            When you visit the Sites, we also collect certain aggregate and non-personally identifiable information ("Non-PII"), as described below. This information does not relate to a single identifiable visitor instead it assists us in tracking aggregate user traffic on the Sites and what pages were accessed.
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Web Beacons
          </p>

          <p>
            Pages on our Sites may contain "web beacons" (also known as Internet tags, pixel tags and clear GIFs). Web beacons allow third parties to obtain information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party. We use log files to store the data that is collected through web beacons.
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Cookie Information
          </p>

          <p>
            A cookie is a small amount of data, which often includes an anonymous unique identifier that is sent to your browser from a website's computer and stored on your computer's hard drive. Cookies record information about your online preferences. Cookies let us know about repeat visitors and if we are delivering quality products and services to our Clients. We may also use cookies to identify which websites our repeat visitors are coming from, so that we can place more of our online advertising on these websites, and can, where necessary, pay our affiliates and other business partners. Each website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other websites. Many websites do this whenever a user visits their website in order to track online traffic flows.
          </p>
          
          <p>
            You can set your browser to accept all cookies, to notify you when a cookie is issued, or not to receive cookies at any time. Each browser is different, so check the "Help" menu of your browser to learn how to change your cookie preferences. For further information on cookies please visit <a style={{ color: 'black', textDecoration: 'underline' }} href="https://www.aboutcookies.org" target="_blank" rel="noreferrer">https://www.aboutcookies.org</a>.
          </p>

          <p style={{ textDecoration: 'underline' }}>
            User Profiles
          </p>

          <p>
            The Company may aggregate PII, Non-PII, Log File Information, Web Beacon Information, and Cookie Information in order to create a profile of a user or users of the Sites.
          </p>

          <p>
            <b>Credit Card Transactions</b>
          </p>
          <p>
            If you provide us with information for credit card purchases for payments through the Sites, we will treat your credit card information in a secure manner.
          </p>

          <p>
            <b>Children's Privacy</b>
          </p>
          <p>
            The Sites is not directed to children under the age of thirteen and we do not knowingly collect personal information from children under the age of thirteen on the Sites. If we become aware that we have inadvertently received personal information from a visitor under the age of thirteen on the Sites, we will delete the information from our records. You must be at least 18 to use the Sites.
          </p>
          
          <p>
            <b>Use of Information</b>
          </p>
          <p>
            By registering on the Sites, you may receive automated or prerecorded telephone calls or automated SMS or text messages in response to your inquiry. By providing your information, you express your consent to receive calls, emails, or SMS or text messages from the Company at the phone number provided, allowing us to respond to inquiries, in order for us to provide you with the Company's products or services, or to provide you with Company's (or its affiliates') marketing materials.
          </p>
          <p>
            The Company may use any PII, Non-PII, Log File Information, Web Beacon Information, Cookie Information, and/or User Profiles (collectively, "User Information"), submitted by you, for any purposes stated herein.
          </p>
          <p>
            Company may use User Information to contact you in response to an inquiry, to provide services or products requested by you, to alert you to changes in services or features, to provide you with requested information regarding Company or its services, and to provide you with Company's (or its affiliates') marketing materials.
          </p>
          <p>
            The Company may use Cookie Information (1) to match a user's Cookie Information to survey information (if any), (2) to track an individual's web browsing habits, (3) to determine which areas of our websites are most frequently visited. This information helps the Company to better understand the online habits of users so that the Company can attempt to customize and target advertising and promotions to users.
          </p>
          <p>
            Company may also sell, rent, license or lease User Information collected on this website to third party marketers.
          </p>
          <p>
            Our partners use this information to recognize you across different channels and platforms over time for advertising, analytics, attribution, and reporting purposes. For example, our partners may deliver an advertisement to you in your web browser based on a purchase you made in a physical retail store, or they may send a personalized marketing email to you based on the fact that you visited a particular website.
          </p>
          <p>
            You may have your telephone number or cell mobile phone number listed on a state or federal do not call registry. By registering and using the Sites, you are waiving your rights and privileges under these laws and expressly giving permission of Company and any agent of Company the right to contact you by telephone or cell phone, and you agree that such act constitutes an inquiry and/or application for purposes of the Amended Telemarketing Sales Rule (16 CFR § 310 et seq.), as amended from time to time (the "Rule"). You are agreeing to have a relationship with the company or companies.
          </p>
          <p>
            We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
          </p>
          <p>
            We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
          </p>
          <p>
            We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          </p>
          <p>
            Please see the opt-out procedures below if you do not want us to share your information with other companies or organizations.
          </p>
          
          <p>
            <b>Opt-Out Policy</b>
          </p>
          <p>
            You may exercise your option to not receive any future communications from us by contacting us at [______________]. Subscribers should note that unsubscribing from the Sites' electronic mailings will not automatically unsubscribe you from any communications you have elected to receive from our partners or other third parties. Please note that you may still receive information from third parties to whom we have disclosed your information to prior to the time you opted-out. You should contact each third party in the event that you no longer want to receive information from such party.
          </p>
          <p>
            You may request by telephone or in writing that Company stop calling you and place your name on our company-specific Do Not Call list. To be added to our Do Not Call list contact us at:
          </p>
          <p>
            8 The Green STE A<br />
            Dover, Delaware 19901 USA<br />
          </p>

          <p style={{ textDecoration: 'underline' }}>
            SMS and Text Messaging
          </p>
          <p>
            If you have provided us with your phone number you may receive SMS or text messages in order for us to provide you with information or to provide the services or products to you. If you later decide you no longer wish to receive marketing, informational or product offers from us, simply reply STOP to the most recent SMS or text message to opt-out of our SMS or text messaging program.
          </p>
          <p>
            Certain SMS or text messages are necessary in connection with the services and products we offer. You will be required to stop using our services prior to ceasing any of these messages.
          </p>

          <p>
            <b>Global Data Processing and Safe Harbor Principles</b>
          </p>
          <p>
            The information that we collect from you may be transferred to, stored at and processed at a destination outside of the U.S. By submitting your personal information, you agree to this transfer, storage and processing. Where the privacy laws of these countries are not as comprehensive as those in the US, we will take steps to ensure that your privacy rights are respected.
          </p>
          <p>
            In order to bridge the different privacy approaches between the U.S. and the European Union, the U.S. Department of Commerce, in consultation with the European Commission, developed a "Safe Harbor" framework. We adhere to the Safe Harbor framework, which includes the privacy principles of Notice, Choice, Onward Transfer, Access and Accuracy, Security, and Oversight/Enforcement.
          </p>

          <p>
            <b>Privacy Complaints</b>
          </p>
          <p>
            If you believe that we have not complied with this Privacy Policy, please contact us at:
          </p>
          <p>
            8 The Green STE A<br />
            Dover, Delaware 19901 USA<br />
          </p>
          <p>
            You may also email us at info@conessi.com and explain thoroughly where Privacy Policy lacked compliance. We will investigate your complaint promptly.
          </p>

          <p>
            <b>How to Access or Modify Your Information</b>
          </p>
          <p>
            We strive to keep all personal information accurate and complete. You are always fully in control of the information that we maintain to service you. You can change your personal information at any time and as often as necessary. You can also request that information be deleted. Information may be withheld or exempted from deletion if this is required by law or is part of a fraud investigation, or as otherwise as described above in the Use of Information section.
          </p>
          <p>
            You may access and modify your personal information by writing to us at:
          </p>
          <p>
            8 The Green STE A<br />
            Dover, Delaware 19901 USA<br />
          </p>

          <p>
            <b>California Residents</b>
          </p>
          <p>
            <b>California Consumer Privacy Act</b>
          </p>
          <p>
            If you are a California resident, the information below also applies to you. Certain terms used in this section have the meanings given to them in the California Consumer Privacy Act of 2018 (“CCPA”).
          </p>
          <p style={{ textDecoration: 'underline' }}>
            Categories of Personal Information we have collected from or about its users in the preceding 12 months
          </p>
          <p>
            •	Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card number)<br />
            •	Characteristics of protected classifications (e.g. gender, age)<br />
            •	Commercial information (e.g. products or services purchased, purchase history)<br />
            •	Internet or other electronic network activity (e.g. browse or search history)<br />
            •	Geolocation data (e.g. latitude or longitude)<br />
            •	Audio, electronic, visual, or similar information (e.g. recording of guest service calls)<br />
            •	Inferences drawn from any of the above (e.g. preferences or characteristics)
          </p>
          <p style={{ textDecoration: 'underline' }}>
            Categories of Sources from which we have collected Personal Information about users (for more information, see the Information We Collect section of this privacy policy).
          </p>
          <p>
            •	Sites<br />
            •	3rd Party Websites<br />
            •	Data Brokers<br />
            •	Social Media
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Business or Commercial purposes for which we have collected or sold Personal Information about users in the preceding 12 months (for more information, see the Use of Information section of this privacy policy).
          </p>
          <p>
            •	Performing services, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytics services, or providing similar services;<br />
            •	Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance;<br />
            •	Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity;<br />
            •	Debugging to identify and repair errors that impair existing intended functionality;<br />
            •	Undertaking internal research for technological development and demonstration; and<br />
            •	Undertaking activities to verify or maintain the quality or safety of a service that is owned, manufactured, manufactured for, or controlled by us, and to improve, upgrade, or enhance the service that is owned, manufactured, manufactured for, or controlled by us.
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Categories of Personal Information we have sold about users in the preceding 12 months
          </p>
          <p>
            •	Internet or other electronic network activity (e.g. “cookies” or other tracking tags)
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Categories of Personal Information we have disclosed for a business purpose in the preceding 12 months
          </p>
          <p>
            •	Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card number)<br />
            •	Characteristics of protected classifications (e.g. gender, age)<br />
            •	Commercial information (e.g. products or services purchased, purchase history)<br />
            •	Internet or other electronic network activity (e.g. browse or search history)
          </p>

          <p style={{ textDecoration: 'underline' }}>
            Non-Discrimination
          </p>
          <p>
            We will not discriminate against you because you exercise your rights. For example, we will not deny you services or charge you a different price or rate for services if you make an access, deletion, or do not sell request.
          </p>
          <p>
            <b>You have the following rights under the CCPA -</b>
          </p>
          <p>
            The right to make requests as follows:
          </p>
          <p>
            <b>Access Request (can make a request no more than twice in a 12-month period) - Request that we disclose the following information</b>
          </p>
          <p>
            - Categories of PI we collected about you during the preceding 12 months
          </p>
          <p>
            - Categories of sources from which PI is collected
          </p>
          <p>
            - Business or commercial purpose for collecting or selling PI
          </p>
          <p>
            - Specific pieces of PI we collected about you
          </p>
          <p>
            - Categories of PI we have sold about you
          </p>
          <p>
            - Categories of third parties to whom the PI was sold
          </p>
          <p>
            - Categories of PI disclosed about you for a business purpose
          </p>
          <p>
            - Categories of third parties to whom PI was disclosed for a business purpose
          </p>

          <p>
            <b>Deletion Request</b>
          </p>
          <p>
            - Request we delete PI that we collected from you
          </p>

          <p>
            <b>Do Not Sell My Info</b>
          </p>
          <p>
            - Request we opt you out of the sale of your PI
          </p>
          
          <p style={{ textDecoration: 'underline' }}>
            Submit Requests
          </p>

          <p>
            <b>Access and Deletion Requests</b>
          </p>

          <p>
            Contact us at info@conessi.com or
          </p>
          <p>
            8 The Green STE A<br />
            Dover, Delaware 19901 USA<br />
          </p>

          {/* <p>
            <b>Do Not Sell My Info Requests</b>
          </p>
          <p>
            Click this [link].
          </p> */}

          <p>
            <b>California Shine the Light</b>
          </p>
          <p>
            If you are a California resident and have an established business relationship with us, you can request a notice disclosing the categories of personal information we have shared with third parties, for the third parties' direct marketing purposes, during the preceding calendar year. To request a notice, please submit your request to 8 The Green STE A, Dover, Delaware 19901 USA. Please allow 30 days for a response.
          </p>
          <p>
            If you are a California resident under 18 years old and a registered user, you can request that we remove content or information that you have posted to our website or other online services. Note that fulfilment of the request may not ensure complete or comprehensive removal (e.g., if the content or information has been reposted by another user). To request removal of content or information, please contact us at 8 The Green STE A, Dover, Delaware 19901 USA.
          </p>

          <p>
            <b>Links to Other Websites</b>
          </p>
          <p>
            We provide links to other websites for your convenience and information. These websites may have their own privacy statements in place, which we recommend you review when you visit any linked websites. We are not responsible for the content of linked websites or any use of such websites.
          </p>
          <p>
            To learn more about interest-based advertising in general and to opt out, please visit <a style={{ color: 'black', textDecoration: 'underline' }} href="https://www.aboutads.info/choices" target="_blank" rel="noreferrer">https://www.aboutads.info/choices.</a>
          </p>

          <p>
            <b>Security</b>
          </p>
          <p>
            We care about the security of your information and to prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place physical, electronic, and managerial procedures to safeguard and encrypt all personal information we collect online. Although we take precautions to protect your data, no data transmission over the Internet can be guaranteed to be 100% secure.
          </p>
          <p>
            In the event any information under our control is compromised as a breach in security, we will take reasonable steps to investigate into the situation and where appropriate, notify those individuals whose information may have been compromised. In such an event, we will take any other steps as may be required by applicable laws and regulations.
          </p>

          <p>
            <b>Changes to This Privacy Policy</b>
          </p>
          <p>
            If we change this Privacy Policy, updates will be posted in this privacy statement and other places we deem appropriate. Information will be used in accordance with the privacy policy under which the information was collected.
          </p>

          <p>
            <b>Contact Us</b>
          </p>
          <p>
            If you have any questions regarding this Privacy Policy, you may email us at info@conessi.com, or write to us at:
          </p>
          <p>
            8 The Green STE A<br />
            Dover, Delaware 19901 USA<br />
          </p>

          <p>
            [1] You are a subscriber if you choose to complete an online survey and/or register for a service, offer or promotion at the Sites.
          </p>
          <p>
            [2] Log files include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the website, track user's movement in the aggregate, and gather broad demographic information for aggregate use.
          </p>
        </div>
      </div>
    </div>
  );
}
