export const Loader = () => {
  return (
    <div className='gradient-loader'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export const DefaultLoader = () => (
  <svg className='spinner' viewBox='0 0 50 50'>
    <circle className='path' cx='25' cy='25' r='20' fill='none' stroke='#8D7A6F' strokeWidth='5'></circle>
  </svg>
);

export const OverlayLoader = () => (
  <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, width: '100vw', background: 'rgba(0, 0, 0, 0.2)', zIndex: 1000,  }}>
    <svg className='spinner' viewBox='0 0 50 50'>
      <circle className='path' cx='25' cy='25' r='20' fill='none' stroke='#8D7A6F' strokeWidth='5'></circle>
    </svg>
  </div>
);
