export const propertyReducer = (prevState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.property;
    case 'NEW_IMAGE':
      return {
        ...prevState,
        images: [...prevState.images, {
          image: action.image
        }],
        madeChanges: true,
      };
    case 'REMOVE_IMAGE':
      return {
        ...prevState,
        images: prevState.images.toSpliced(action.index, 1),
        madeChanges: true,
      };
    case 'REORDER_IMAGE':
      return {
        ...prevState,
        images: action.images,
        madeChanges: true,
      };
    case 'IMAGE_DESCRIPTION':
      const newData = [...prevState.images];
      newData[action.index] = {
        ...newData[action.index],
        description: action.value,
      };

      return {
        ...prevState,
        images: newData,
        madeChanges: true,
      };
    case 'IMAGE':
      return {
        ...prevState,
        image: action.image,
        madeChanges: true,
      };
    case 'NAME':
      return {
        ...prevState,
        name: action.name,
        madeChanges: true,
      };
    case 'TYPE':
      return {
        ...prevState,
        type: action.value,
        madeChanges: true,
      };
    case 'DESCRIPTION':
      return {
        ...prevState,
        description: action.description,
        madeChanges: true,
      };
    case 'ADDRESS_LINE1':
      return {
        ...prevState,
        address: {
          ...prevState.address,
          line1: action.line1,
        },
        madeChanges: true,
      };
    case 'ADDRESS_LINE2':
      return {
        ...prevState,
        address: {
          ...prevState.address,
          line2: action.line1,
        },
        madeChanges: true,
      };
    case 'ADDRESS_CITY':
      return {
        ...prevState,
        address: {
          ...prevState.address,
          city: action.city,
        },
        madeChanges: true,
      };
    case 'ADDRESS_STATE':
      return {
        ...prevState,
        address: {
          ...prevState.address,
          state: action.state,
        },
        madeChanges: true,
      };
    case 'ADDRESS_ZIP':
      return {
        ...prevState,
        address: {
          ...prevState.address,
          zip: action.zip,
        },
        madeChanges: true,
      };
    case 'BEDS':
      return {
        ...prevState,
        details: {
          ...prevState.details,
          beds: action.beds,
        },
        madeChanges: true,
      };
    case 'BATHS':
      return {
        ...prevState,
        details: {
          ...prevState.details,
          baths: action.baths,
        },
        madeChanges: true,
      };
    case 'FRIDGE':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          fridge: action.value,
        },
        madeChanges: true,
      };
    case 'DISHWASHER':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          dishwasher: action.value,
        },
        madeChanges: true,
      };
    case 'GARBAGE_DISPOSAL':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          garbage_disposal: action.value,
        },
        madeChanges: true,
      };
    case 'WASHING_MACHINE':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          washing_machine: action.value,
        },
        madeChanges: true,
      };
    case 'DRYER':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          dryer: action.value,
        },
        madeChanges: true,
      };
    case 'STOVE_INCLUDED':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          stove: action.value,
        },
        madeChanges: true,
      };
    case 'STOVE_TYPE':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          stove_type: action.value,
        },
        madeChanges: true,
      };
    case 'WINE_COOLER':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          wine_cooler: action.value,
        },
        madeChanges: true,
      };
    case 'MICROWAVE':
      return {
        ...prevState,
        appliances: {
          ...prevState.appliances,
          microwave: action.value,
        },
        madeChanges: true,
      };
    case 'HEAT_TYPE':
      return {
        ...prevState,
        heat: {
          ...prevState.heat,
          unit: action.value,
        },
        madeChanges: true,
      };
    case 'HEAT_SOURCE':
      return {
        ...prevState,
        heat: {
          ...prevState.heat,
          source: action.value,
        },
        madeChanges: true,
      };
    case 'AC':
      return {
        ...prevState,
        ac: action.value,
        madeChanges: true,
      };
    case 'ADD_FLOORING':
      const current = [...prevState.flooring];
      const idx = current.indexOf(action.value);
      if (idx === -1) {
        current.push(action.value);
      }

      return {
        ...prevState,
        flooring: current,
        madeChanges: true,
      };
    case 'REMOVE_FLOORING':
      return {
        ...prevState,
        flooring: prevState.flooring.toSpliced(action.value, 1),
        madeChanges: true,
      };
    case 'PARKING_SPOTS':
      return {
        ...prevState,
        parking: {
          ...prevState.parking,
          spots: action.value,
        },
        madeChanges: true,
      };
    case 'PARKING_FEE':
      return {
        ...prevState,
        parking: {
          ...prevState.parking,
          fee: action.value,
        },
        madeChanges: true,
      };
    case 'PARKING_DRIVEWAY':
      return {
        ...prevState,
        parking: {
          ...prevState.parking,
          driveway: action.value,
        },
        madeChanges: true,
      };
    case 'PARKING_GARAGE':
      return {
        ...prevState,
        parking: {
          ...prevState.parking,
          garage: action.value,
        },
        madeChanges: true,
      };
    case 'PARKING_STREET':
      return {
        ...prevState,
        parking: {
          ...prevState.parking,
          street: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_E':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          electric: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_G':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          gas: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_T':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          trash: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_R':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          recycle: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_W':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          water: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_WI':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          wifi: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_L':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          landscaping: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_SR':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          snowRemoval: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_HS':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          homeSecurity: action.value,
        },
        madeChanges: true,
      };
    case 'UTILITIES_PC':
      return {
        ...prevState,
        utilities: {
          ...prevState.utilities,
          pestControl: action.value,
        },
        madeChanges: true,
      };
    case 'AVAILABLE_DATE':
      return {
        ...prevState,
        available: action.value,
        madeChanges: true,
      };
    case 'SCHOOL':
      return {
        ...prevState,
        school: action.school,
        madeChanges: true,
      };
    case 'RENT':
      return {
        ...prevState,
        rent: action.value,
        madeChanges: true,
      };
    case 'SECURITY_DEPOSIT':
      return {
        ...prevState,
        security_deposit: action.value,
        madeChanges: true,
      };
    case 'APPLICATION_FEE':
      return {
        ...prevState,
        application_fee: action.value,
        madeChanges: true,
      };
    case 'PETS_ALLOWED':
      return {
        ...prevState,
        pets: {
          ...prevState.pets,
          allowed: action.value,
        },
        madeChanges: true,
      };
    case 'PETS_DEPOSIT':
      return {
        ...prevState,
        pets: {
          ...prevState.pets,
          deposit: action.value,
        },
        madeChanges: true,
      };
    case 'PETS_MONTHLY':
      return {
        ...prevState,
        pets: {
          ...prevState.pets,
          monthly: action.value,
        },
        madeChanges: true,
      };
    case 'RENTERS_INSURANCE':
      return {
        ...prevState,
        renters_insurance: action.value,
        madeChanges: true,
      };
    case 'PERSONAL_GUARANTOR':
      return {
        ...prevState,
        personal_guarantor: action.value,
        madeChanges: true,
      };
    case 'CANCEL_EDITS':
      return {
        ...action.property,
        madeChanges: false,
      };
    default:
      return prevState;
  }
};
