export const getImageBody = (file, filename, dimensionsX, dimensionsY) => {
  let dataUrl;
  let mimeType;
  let dimensions;
  if (typeof file === 'string' && file.indexOf('data:image') === 0) {
    dataUrl = file;
    mimeType = file.split(',')[0].split(':')[1].split(';')[0];
    filename = `${filename}.${mimeType.split('/')[1].split('+')[0]}`;
  } else {
    console.log('file type not supported');
    return;
  }

  if (dimensionsX && dimensionsY) {
    dimensions = {
      x: dimensionsX,
      y: dimensionsY,
    }
  }

  dataUrl = dataUrl.replace(/^data:image\/(png|jpeg|svg|svg\+xml|gif);base64,/, '');

  return {
    base64Data: dataUrl,
    filename,
    mimeType,
    dimensions,
  };
}
