import { useReducer, useState, useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DefaultLoader } from '../../components/Loader';
import { Prompt } from '../../components/Prompt';
import { PropertyImage } from '../../components/CustomImages';
import { stateAbbreviatons } from '../../constants/helpers';
import { getProperty, updateProperty, uploadPropertyImage, publishProperty, unpublishProperty } from '../../services/landlord';
import { propertyReducer } from '../../reducers/property_landlord';

import file_upload from '../../assets/svgs/file_upload.svg';
import info from '../../assets/svgs/info.svg';
import close from '../../assets/svgs/close.svg';
import back_arrow from '../../assets/svgs/back_arrow.svg';

const propertyTypes = ['Apartment', 'House', 'Townhome'];
const stoveTypes = ['Electric', 'Gas', 'Induction'];
const heatTypes = ['Central', 'Electric Baseboard', 'Mini Split', 'Radiator'];
const heatSources = ['Electric', 'Gas', 'Oil'];
const acTypes = ['Central', 'Mini Split', 'Window', 'None'];
const flooringTypes = ['Carpet', 'Hardwood', 'Laminate', 'Luxury Vinyl Plank', 'Tile'];

export const loader = async({ params }) => getProperty(params.propertyId);

export default function Property() {
  const fileInput = useRef(null);
  const propertyData = useLoaderData();

  const navigate = useNavigate();

  const [property, dispatch] = useReducer(propertyReducer, propertyData.property);
  const schoolList = propertyData.schools;
  const [isLoading, setLoading] = useState(false);
  const [dataNeeded, setDataNeeded] = useState(propertyData.needed);

  const uploadImage = async (image) => {
    return uploadPropertyImage(property, image)
      .then(async (imageUrl) => {
        dispatch({ type: 'NEW_IMAGE', image: imageUrl });
        return;
      })
      .then(null, (err) => console.log(err));
  }

  const saveProperty = async () => {
    // validate info
    if (property.appliances?.stove && !property.appliances.stove_type) return alert('Please select a stove type');

    setLoading(true);

    updateProperty(property)
      .then(({ property, needed }) => {
        dispatch({ type: 'INITIAL_STATE', property });
        setDataNeeded(needed);
        setLoading(false);

        if (needed.length === 0) {
          const confirm = window.confirm('Success! It looks like you have everything you need. Would you like to publish this listing now?');
          if (confirm) publishClick();
        } else alert('Success!');
      })
      .then(null, (err) => console.log(err.err));
  }

  const cancelEdits = () => {
    getProperty(property._id)
      .then(({ property }) => {
        dispatch({ type: 'CANCEL_EDITS', property });
      })
      .then(null, (err) => console.log(err));
  }

  const publishClick = () => {
    publishProperty(property._id)
      .then(async({ success, error }) => {
        if (success) {
          await alert('Publish successful!');
          navigate(-1);
        } else {
          if (error === 'Needs information') await alert('Failed to publish. Please make sure all required information is correctly filled out.');
        }
      })
      .then(null, (err) => console.log(err));
  }

  const unpublishClick = async() => {
    const confirm = await window.confirm('Are you sure you want to remove this listing? Students will no longer be able to see this listing.');

    if (confirm) {
      unpublishProperty(property._id)
        .then(async (res) => {
          await alert('Unpublish successful')
          navigate(-1);
        })
        .then(null, (err) => console.log(err));
    }
  }

  const removeImage = (index) => {
    dispatch({ type: 'REMOVE_IMAGE', index });
  }

  const pickImage = async () => {
    if (property.active) return alert('Can not make changes to active listing. Please remove to make changes.');
    if (property.active) return alert('Can not make changes to active listing. Please remove to make changes.');
    fileInput.current.click();
  };

  const handleImageChange = async({ target }) => {
    const files = target.files;

    for (const file of files) {
      if (!(/^image\/(png|jpe?g)$/).test(file.type)) await alert(`${file.name} is not a valid type. File must be .png, .jpg, or .jpeg`);
      else if (file.size > 3145728) await alert(`${file.name} is too large. File size can not be greater than 3MB.`);
      else {
        const reader = new FileReader();
        reader.addEventListener('load', async() => {
          // convert image file to base64 string
          const uri = reader.result;
          setLoading(true);
          await uploadImage(uri);
          setLoading(false);
        },
          false,
        );

        reader.readAsDataURL(file);
      }
    }
  }

  const reorder = (fromIndex, toIndex) => {
    const copy = [...property.images]; // Don't modify react data in-place
    const removed = copy.splice(fromIndex, 1);

    copy.splice(toIndex, 0, removed[0]); // Now insert at the new pos
    // setData(copy);
    dispatch({ type: 'REORDER_IMAGE', images: copy });
  }

  const Image = ({ item, index }) => {
    const first = index === 0;
    const last = index === property.images.length - 1;

    return (
      <div className='sortable-image' style={{ position: 'relative', ...styles.propertyImage }}>
        <PropertyImage image={item} style={styles.propertyImage} />
        {!first && !property.active &&
          <div className='sortable-image-controls image-control-left' onClick={() => reorder(index, index - 1)}>
            <div style={{ color: 'white', fontWeight: '600', fontSize: 24 }}>{'<'}</div>
          </div>
        }

        {!last && !property.active &&
          <div className='sortable-image-controls image-control-right' onClick={() => reorder(index, index + 1)}>
            <div style={{ color: 'white', fontWeight: '600', fontSize: 24 }}>{'>'}</div>
          </div>
        }
      </div>
    );
  }

  return (
    <div className='page-container' style={{ paddingBottom: 60 }}>
      {isLoading && <DefaultLoader />}
      <Prompt
        when={property.madeChanges}
        message='You may have unsaved changes. Are you sure to discard them and leave this page?'
      />

      <div>

        <div style={{ display: 'inline-block' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 12, alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate(-1)} >
            <img src={back_arrow} style={{ width: 24, height: 24 }} alt='Back'/>
            <div style={{ fontWeight: 'bold', fontSize: 20 }}>Back</div>
          </div>
        </div>

        {property.madeChanges && (
          <div style={styles.buttonContainer}>
            <button className='clear-button' onClick={cancelEdits}>Cancel</button>
            <button className='solid-button' onClick={saveProperty}>Save</button>
          </div>
        )}

        {!property.madeChanges && !property.active && (
          <div style={styles.buttonContainer}>
            <div style={{ position: 'relative' }}>
              <button className='solid-button publish-btn' disabled={!dataNeeded || dataNeeded.length > 0} onClick={publishClick}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
                  {(!dataNeeded || dataNeeded.length > 0) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={info} style={{ width: 16, height: 16 }} alt='Needs Information' />
                    </div>
                  )}
                  Publish
                </div>
              </button>
              <div className='publish-tooltip'>
                <div>Missing:</div>
                {dataNeeded?.map((value) => (
                  <div key={value}>{value}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {!property.madeChanges && property.active && (
          <div style={styles.buttonContainer}>
            <button className='clear-button' onClick={unpublishClick}>Unpublish</button>
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
          <div style={{ ...styles.title, marginTop: 0, marginRight: 4, cursor: 'pointer' }} onClick={pickImage}>Images</div>
          <img src={file_upload} style={{ width: 24, height: 24, cursor: 'pointer' }} alt='Upload' onClick={pickImage} />
          <input type='file' onChange={handleImageChange} style={{ display: 'none' }} ref={fileInput} multiple accept='.png,.jpg,.jpeg'/>
        </div>
        {property?.images?.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 16 }}>
            {property.images.map((value, idx) => (
              <div key={idx} style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center' }}>
                <Image item={value.image} index={idx} />
                <input
                  type='text'
                  className='text-input'
                  disabled={property.active}
                  onChange={({ target }) => dispatch({ type: 'IMAGE_DESCRIPTION', index: idx, value: target.value })}
                  value={value.description || ''}
                  placeholder='Description...'
                />
                {!property.active && (
                  <div style={{ cursor: 'pointer' }} onClick={() => removeImage(idx)}>
                    <img src={close} style={{ width: 24, height: 24 }} alt='Remove' />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {(!property.images || property.images.length === 0) && (
          <PropertyImage image={''} style={styles.propertyImage} />
        )}

        <div style={{ width: '100%', maxWidth: 500 }}>
          <div style={{ ...styles.title, marginTop: 40 }}>Name</div>
          <div style={styles.inputContainer}>
            <input
              autoCapitalize='words'
              autoCorrect='false'
              className='text-input'
              onChange={({ target }) => dispatch({ type: 'NAME', name: target.value })}
              disabled={property.active}
              placeholder='Property Name'
              type='text'
              value={property.name || ''}
            />
            <div style={styles.label}>Property Name</div>
          </div>
        </div>

        <div style={styles.title}>Listing Description</div>
        <div style={styles.inputContainer}>
          <textarea
            autoCapitalize='words'
            autoCorrect='false'
            onChange={({ target }) => dispatch({ type: 'DESCRIPTION', description: target.value })}
            disabled={property.active}
            placeholder='Description'
            type='text'
            style={styles.textarea}
            value={property.description || ''}
          ></textarea>
          <div style={styles.label}>Property Description</div>
        </div>

        <div style={{ width: '100%', maxWidth: 500 }}>
          <div style={styles.title}>Address</div>
          <div style={styles.inputContainer}>
            <input
              autoCapitalize='words'
              autoCorrect='false'
              className='text-input'
              onChange={({ target }) => dispatch({ type: 'ADDRESS_LINE1', line1: target.value })}
              disabled={property.active}
              placeholder='Address (Line 1)'
              type='text'
              value={property.address?.line1 || ''}
            />
            <div style={styles.label}>Address (Line 1)</div>
          </div>

          <div style={styles.inputContainer}>
            <input
              autoCapitalize='words'
              autoCorrect='false'
              className='text-input'
              onChange={({ target }) => dispatch({ type: 'ADDRESS_LINE2', line2: target.value })}
              disabled={property.active}
              placeholder='Address (Line 2)'
              type='text'
              value={property.address?.line2 || ''}
            />
            <div style={styles.label}>Address (Line 2)</div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <div style={{ ...styles.inputContainer, flexShrink: 1 }}>
              <input
                autoCapitalize='words'
                autoCorrect='false'
                className='text-input'
                onChange={({ target }) => dispatch({ type: 'ADDRESS_CITY', city: target.value })}
                disabled={property.active}
                placeholder='City'
                type='text'
                value={property.address?.city || ''}
              />
              <div style={styles.label}>City</div>
            </div>

            <div style={{ ...styles.inputContainer, flexShrink: 1 }}>
              <Select
                value={property.address?.state ? { value: property.address?.state || '', label: property.address?.state || '' } : null}
                onChange={({ value }) => dispatch({ type: 'ADDRESS_STATE', state: value })}
                isDisabled={property.active}
                options={stateAbbreviatons.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Select State...'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
              <div style={styles.label}>State</div>
            </div>

            <div style={{ ...styles.inputContainer, flexShrink: 1 }}>
              <input
                autoCapitalize='words'
                autoCorrect='false'
                maxLength={5}
                onChange={({ target }) => dispatch({ type: 'ADDRESS_ZIP', zip: target.value })}
                disabled={property.active}
                placeholder='Zip'
                type='number'
                value={property.address?.zip || ''}
              />
              <div style={styles.label}>Zip</div>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 40, marginTop: 40 }}>

          <div style={styles.sectionContainer}>
            <div style={styles.title}>Details</div>
            <div style={styles.inputContainer}>
              <Select
                value={property.type ? { value: property.type, label: property.type } : null}
                onChange={({ value }) => dispatch({ type: 'TYPE', value: value })}
                isDisabled={property.active}
                options={propertyTypes.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Select Type...'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
              <div style={styles.label}>Property Type</div>
            </div>

            <div style={styles.inputContainer}>
              <Select
                value={{ value: property.details?.beds, label: property.details?.beds }}
                onChange={({ value }) => dispatch({ type: 'BEDS', beds: value })}
                isDisabled={property.active}
                options={['Studio', 1, 2, 3, 4, 5, 6].map((option) => ({
                  value: option,
                  label: option,
                }))}
                defaultValue={'Beds'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
              <div style={styles.label}>Number of Bedrooms</div>

              <div style={styles.inputContainer}>
                <Select
                  value={property.details?.baths ? { value: property.details?.baths, label: property.details?.baths } : null}
                  onChange={({ value }) => dispatch({ type: 'BATHS', baths: value })}
                  isDisabled={property.active}
                  options={[1, 1.5, 2, 2.5, 3, 3.5, 4].map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  placeholder={'Baths'}
                  styles={selectStyles}
                  maxMenuHeight={250}
                />
                <div style={styles.label}>Number of Bathrooms</div>
              </div>
            </div>


            <div style={styles.title}>Parking</div>
            <div style={styles.inputContainer}>
              <div style={styles.inputContainer}>
                <input
                  autoCapitalize='words'
                  autoCorrect='false'
                  min={0}
                  onChange={({ target }) => dispatch({ type: 'PARKING_FEE', value: target.value })}
                  disabled={property.active}
                  placeholder='Fee'
                  type='number'
                  value={property.parking?.fee || ''}
                />
                <div style={styles.label}>Parking Fee ($)</div>
              </div>

              <Select
                value={property.parking?.spots ? { value: property.parking.spots, label: property.parking.spots } : null}
                onChange={({ value }) => dispatch({ type: 'PARKING_SPOTS', value })}
                isDisabled={property.active}
                options={[1, 2, 3, 4, 5, 6].map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Parking Spots...'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
              <div style={styles.label}>Number of Parking Spots</div>
              <div style={styles.checkContainer}>
                <input
                  type='checkbox'
                  style={styles.checkbox}
                  checked={property.parking?.driveway || false}
                  onChange={({ target }) => dispatch({ type: 'PARKING_DRIVEWAY', value: target.checked })}
                  disabled={property.active}
                />
                <div>Driveway</div>
              </div>
              <div style={styles.checkContainer}>
                <input
                  type='checkbox'
                  style={styles.checkbox}
                  checked={property.parking?.garage || false}
                  onChange={({ target }) => dispatch({ type: 'PARKING_GARAGE', value: target.checked })}
                  disabled={property.active}
                />
                <div>Garage</div>
              </div>
              <div style={styles.checkContainer}>
                <input
                  type='checkbox'
                  style={styles.checkbox}
                  checked={property.parking?.street || false}
                  onChange={({ target }) => dispatch({ type: 'PARKING_STREET', value: target.checked })}
                  disabled={property.active}
                />
                <div>Street</div>
              </div>
            </div>

          </div>

          <div style={styles.sectionContainer}>
            
            <div style={styles.title}>Lease Information</div>
            {/* <div style={styles.inputContainer}>
              <Select
                value={{ value: property.school, label: property.school }}
                onChange={({ value }) => dispatch({ type: 'SCHOOL', school: value })}
                isDisabled={property.active}
                options={schoolList.map((option) => ({
                  value: option,
                  label: option,
                }))}
                defaultValue={'School'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
              <div style={styles.label}>School</div>
            </div> */}

            <div style={styles.inputContainer}>
              <DatePicker
                onChange={(date) => dispatch({ type: 'AVAILABLE_DATE', value: date })}
                disabled={property.active}
                className='text-input'
                selected={new Date(property.available || Date.now())}
                wrapperClassName='property-date-picker'
              />
              <div style={styles.label}>Lease Start</div>
            </div>

            <div style={styles.inputContainer}>
              <input
                autoCapitalize='words'
                autoCorrect='false'
                min={0}
                onChange={({ target }) => dispatch({ type: 'RENT', value: target.value })}
                disabled={property.active}
                placeholder='Rent (Per Month)'
                type='number'
                value={property.rent || ''}
              />
              <div style={styles.label}>Rent ($)</div>
            </div>

            <div style={styles.inputContainer}>
              <input
                autoCapitalize='words'
                autoCorrect='false'
                min={0}
                onChange={({ target }) => dispatch({ type: 'SECURITY_DEPOSIT', value: target.value })}
                disabled={property.active}
                placeholder='Security Deposit'
                type='number'
                value={property.security_deposit || ''}
              />
              <div style={styles.label}>Security Deposit ($)</div>
            </div>

            <div style={styles.inputContainer}>
              <input
                autoCapitalize='words'
                autoCorrect='false'
                min={0}
                onChange={({ target }) => dispatch({ type: 'APPLICATION_FEE', value: target.value })}
                disabled={property.active}
                placeholder='Application Fee'
                type='number'
                value={property.application_fee >= 0 ? property.application_fee : ''}
              />
              <div style={styles.label}>Application Fee ($)</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.pets?.allowed || false}
                onChange={({ target }) => dispatch({ type: 'PETS_ALLOWED', value: target.checked })}
                disabled={property.active}
              />
              <div>Pets Allowed</div>
            </div>
            {property.pets?.allowed && (
              <>
                <div style={styles.inputContainer}>
                  <input
                    autoCapitalize='words'
                    autoCorrect='false'
                    min={0}
                    onChange={({ target }) => dispatch({ type: 'PETS_DEPOSIT', value: target.value })}
                    disabled={property.active}
                    placeholder='Pet Deposit'
                    type='number'
                    value={property.pets?.deposit >= 0 ? property.pets.deposit : ''}
                  />
                  <div style={styles.label}>Pet Deposit ($)</div>
                </div>

                <div style={styles.inputContainer}>
                  <input
                    autoCapitalize='words'
                    autoCorrect='false'
                    min={0}
                    onChange={({ target }) => dispatch({ type: 'PETS_MONTHLY', value: target.value })}
                    disabled={property.active}
                    placeholder='Pet Monthly Fee'
                    type='number'
                    value={property.pets?.monthly >= 0 ? property.pets.monthly : ''}
                  />
                  <div style={styles.label}>Pet Monthly Fee ($)</div>
                </div>
              </>
            )}

            <div style={{ ...styles.checkContainer, marginTop: 8 }}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.renters_insurance || false}
                onChange={({ target }) => dispatch({ type: 'RENTERS_INSURANCE', value: target.checked })}
                disabled={property.active}
              />
              <div>Renter's Insurance Required</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.personal_guarantor || false}
                onChange={({ target }) => dispatch({ type: 'PERSONAL_GUARANTOR', value: target.checked })}
                disabled={property.active}
              />
              <div>Personal Guarantor Required</div>
            </div>
          </div>

          <div style={styles.sectionContainer}>
            <div style={{ ...styles.title, marginBottom: 8 }}>Appliances</div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.fridge || false}
                onChange={({ target }) => dispatch({ type: 'FRIDGE', value: target.checked })}
                disabled={property.active}
              />
              <div>Refridgerator</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.dishwasher || false}
                onChange={({ target }) => dispatch({ type: 'DISHWASHER', value: target.checked })}
                disabled={property.active}
              />
              <div>Dishwasher</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.garbage_disposal || false}
                onChange={({ target }) => dispatch({ type: 'GARBAGE_DISPOSAL', value: target.checked })}
                disabled={property.active}
              />
              <div>Garbage Disposal</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.washing_machine || false}
                onChange={({ target }) => dispatch({ type: 'WASHING_MACHINE', value: target.checked })}
                disabled={property.active}
              />
              <div>Washing Machine</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.dryer || false}
                onChange={({ target }) => dispatch({ type: 'DRYER', value: target.checked })}
                disabled={property.active}
              />
              <div>Dryer</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.wine_cooler || false}
                onChange={({ target }) => dispatch({ type: 'WINE_COOLER', value: target.checked })}
                disabled={property.active}
              />
              <div>Wine Cooler</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.microwave || false}
                onChange={({ target }) => dispatch({ type: 'MICROWAVE', value: target.checked })}
                disabled={property.active}
              />
              <div>Microwave</div>
            </div>

            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.appliances?.stove || false}
                onChange={({ target }) => dispatch({ type: 'STOVE_INCLUDED', value: target.checked })}
                disabled={property.active}
              />
              <div>Stove</div>
            </div>
            {property.appliances?.stove && (
              <Select
                value={property.appliances?.stove_type ? { value: property.appliances?.stove_type, label: property.appliances?.stove_type } : null}
                onChange={({ value }) => dispatch({ type: 'STOVE_TYPE', value })}
                isDisabled={property.active}
                options={stoveTypes.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Select Type...'}
                styles={selectStyles}
                maxMenuHeight={250}
              />
            )}
          </div>


          <div style={styles.sectionContainer}>
            <div style={{ ...styles.title, marginBottom: 8 }}>Heating/Cooling</div>
            <Select
              value={property.heat?.unit ? { value: property.heat.unit, label: property.heat.unit } : null}
              onChange={({ value }) => dispatch({ type: 'HEAT_TYPE', value })}
              isDisabled={property.active}
              options={heatTypes.map((option) => ({
                value: option,
                label: option,
              }))}
              placeholder={'Select Type...'}
              styles={selectStyles}
              maxMenuHeight={250}
            />
            <div style={styles.label}>Heat Type</div>

            <Select
              value={property.heat?.source ? { value: property.heat.source, label: property.heat.source } : null}
              onChange={({ value }) => dispatch({ type: 'HEAT_SOURCE', value })}
              isDisabled={property.active}
              options={heatSources.map((option) => ({
                value: option,
                label: option,
              }))}
              placeholder={'Select Source...'}
              styles={selectStyles}
              maxMenuHeight={250}
            />
            <div style={styles.label}>Heat Source</div>

            <Select
              value={property.ac ? { value: property.ac, label: property.ac } : null}
              onChange={({ value }) => dispatch({ type: 'AC', value })}
              isDisabled={property.active}
              options={acTypes.map((option) => ({
                value: option,
                label: option,
              }))}
              placeholder={'Select Type...'}
              styles={selectStyles}
              maxMenuHeight={250}
            />
            <div style={styles.label}>Air Conditioning</div>
          </div>


          <div style={styles.sectionContainer}>
            <div style={{ ...styles.title, marginBottom: 8 }}>Flooring</div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 4, flexWrap: 'wrap', marginBottom: 8 }}>
              {property.flooring.map((value, idx) => (
                <div key={idx} style={{ border: '1px solid black', borderRadius: 5, padding: '4px 12px', display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }}>
                  <div>{value}</div>
                  {!property.active && (
                    <img src={close} style={{ width: 16, height: 16, cursor: 'pointer' }} alt='Remove' onClick={() => dispatch({ type: 'REMOVE_FLOORING', value: idx })}/>
                  )}
                </div>
              ))}
            </div>
            <Select
              onChange={({ value }) => dispatch({ type: 'ADD_FLOORING', value })}
              isDisabled={property.active}
              options={flooringTypes.map((option) => ({
                value: option,
                label: option,
              }))}
              placeholder={'Select Type...'}
              styles={selectStyles}
              maxMenuHeight={250}
            />
            <div style={styles.label}>Flooring</div>
          </div>
        
        
          <div style={styles.sectionContainer}>
            <div style={{ ...styles.title, marginBottom: 8 }}>Included Utilities</div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.electric || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_E', value: target.checked })}
                disabled={property.active}
              />
              <div>Electric</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.gas || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_G', value: target.checked })}
                disabled={property.active}
              />
              <div>Gas/Oil</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.homeSecurity || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_HS', value: target.checked })}
                disabled={property.active}
              />
              <div>Home Security</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.landscaping || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_L', value: target.checked })}
                disabled={property.active}
              />
              <div>Landscaping</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.pestControl || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_PC', value: target.checked })}
                disabled={property.active}
              />
              <div>Pest Control</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.recycle || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_R', value: target.checked })}
                disabled={property.active}
              />
              <div>Recycle</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.snowRemoval || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_SR', value: target.checked })}
                disabled={property.active}
              />
              <div>Snow Removal</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.trash || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_T', value: target.checked })}
                disabled={property.active}
              />
              <div>Trash</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.water || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_W', value: target.checked })}
                disabled={property.active}
              />
              <div>Water/Sewer</div>
            </div>
            <div style={styles.checkContainer}>
              <input
                type='checkbox'
                style={styles.checkbox}
                checked={property.utilities?.wifi || false}
                onChange={({ target }) => dispatch({ type: 'UTILITIES_WI', value: target.checked })}
                disabled={property.active}
              />
              <div>Wifi</div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

const styles = {
  propertyImage: {
    width: 200,
    height: 280,
    aspectRatio: 5 / 7,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 12,
  },
  propertyName: {
    fontSize: 16,
    marginBottom: 4,
  },
  smallText: {
    fontSize: 12,
    color: '#46483E',
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 1,
    marginTop: 12,
    marginBottom: 12,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
  },
  label: {
    fontSize: 10,
  },
  title: {
    fontSize: 20,
    marginTop: 12,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 24,
  },
  button: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  buttonSave: {
    backgroundColor: '#CCBFB9',
  },
  buttonSaveActive: {
    backgroundColor: '#B5AAA5',
  },
  buttonText: {
    color: '#F4F3F1',
  },
  buttonDelete: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  buttonDeleteActive: {
    backgroundColor: '#B3735A',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  deleteText: {
    color: '#46483E',
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: '100%',
    alignItems: 'center',
  },
  checkLabel: {
    fontSize: 14,
  },
  select: {
    display: 'none',
  },
  textarea: {
    borderBottom: '1px solid #D3D3D3',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    backgroundColor: 'transparent',
    minHeight: 38,
    height: 104,
    outlineStyle: 'none',
    width: '100%',
    borderRadius: 0,
    padding: 0,
    boxShadow: 'none',
    minWidth: '100%',
    maxWidth: '100%',
  },
  sectionContainer: {
    width: '100%',
    maxWidth: 300,
  },
};

const selectStyles = {
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    backgroundColor: 'transparent',
    // zIndex: 100,
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderRadius: 0,
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    height: 38,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 38,
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? '#D3D3D3' : 'white',
    color: 'black',
  }),
  menu: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    zIndex: 10000,
  }),
};
