import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLoaderData } from 'react-router-dom';
import Select from 'react-select';

import { AuthContext } from '../components/AuthContext';
import { updateMatchAnswer, createMatchResults, quizQuestions } from '../services/student';
import { Loader } from '../components/Loader';

export const loader = async ({ params }) => quizQuestions();

export default function Match() {
  const { state, authContext } = useContext(AuthContext);
  const navigate = useNavigate();
  const { questions, finishedQuiz, schools } = useLoaderData();

  const [currentPage, setPage] = useState(0);
  const [finished, setFinished] = useState(false);
  const [retake, setRetake] = useState(finishedQuiz);
  const [monthSelection, setMonthSelection] = useState(null);
  const [yearSelection, setYearSelection] = useState(null);
  const [school, setSchool] = useState(state.user.school || null);

  useEffect(() => {
    if (school) {
      const newUser = structuredClone(state.user);
      newUser.school = school.value;
      authContext.updateUser(newUser, state);
    }
  }, [school]);

  useEffect(() => {
    if (monthSelection && yearSelection) {
      handleQuestion(new Date(yearSelection.value, monthSelection.value));
    }
  }, [monthSelection, yearSelection]);

  const resetQuiz = () => {
    setRetake(false);
  }

  const finishQuiz = () => {
    createMatchResults()
      .then((success) => {
        setTimeout(() => {
          navigate('/student/matches');
        }, 5000);
      })
      .then(null, (err) => console.log(err));
  }

  const saveAnswer = (property, answer) => {
    updateMatchAnswer(property, answer)
      .then((success) => {
        if (success) {
          if (currentPage === questions.length - 1) {
            setFinished(true);
            finishQuiz();
          } else {
            setPage(currentPage + 1);
            authContext.refreshUser(state);
          }
        }
      })
      .then(null, (err) => console.log(err));
  }

  const handleQuestion = (selection) => {
    const { property } = questions[currentPage];

    saveAnswer(property, selection);
  }

  const Question = () => (
    <>
      <div style={styles.question}>{questions[currentPage].prompt}</div>
      <div style={{ marginTop: 24 }}>
        {questions[currentPage].property === 'available' ? (
          <div style={{display: 'flex', flexDirection: 'column', gap: 12 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
              <Select
                value={monthSelection}
                onChange={setMonthSelection}
                options={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((option, idx) => ({
                  value: idx,
                  label: option,
                }))}
                placeholder={'Month...'}
                styles={selectStyles('month')}
                maxMenuHeight={250}
              />
              <Select
                value={yearSelection}
                onChange={setYearSelection}
                options={['2023', '2024', '2025', '2026', '2027', '2028'].map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Year...'}
                styles={selectStyles('year')}
                maxMenuHeight={250}
              />
            </div>

          </div>
        )
      :
      (
        <>
          {questions[currentPage].options.map((value, idx) => (
            <div key={idx} className='quiz-option' onClick={() => handleQuestion(value)}>
              <div>{value}</div>
            </div>
          ))}
        </>
      )}
      </div>
    </>
  );

  return (
    <div style={styles.container}>

      <div style={styles.title}>Find Your Conessi Match</div>
      <div style={styles.quizContainer}>
        {!state.user.school && (
          <div>
            <div style={{ ...styles.question, justifyContent: 'center' }}>Please select your school:</div>
            <div style={{ marginTop: 24 }}>
              <Select
                value={school}
                onChange={setSchool}
                options={schools.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={'Your School...'}
                styles={selectStyles('school')}
                maxMenuHeight={250}
              />
            </div>
          </div>
        )}

        {state.user.school && !finished && !retake && <Question />}

        {state.user.school && finished && !retake && (
          <>
            <Loader />
            <div style={{ marginTop: 40, textAlign: 'center', fontFamily: 'Maharlika' }}>Please wait while we find<br />your matches...</div>
          </>
        )}

        {state.user.school && retake && (
          <div>
            <div style={{ fontFamily: 'Maharlika', marginBottom: 24, textAlign: 'center' }}>
              It looks like you have already completed the quiz. Would you like to retake the quiz or view your matches?
            </div>
            <div style={{ display: 'flex', gap: 12 }}>
              <button className='clear-button' onClick={resetQuiz}>Retake Quiz</button>
              <Link to='/student/matches'>
                <button className='solid-button'>View Matches</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  quizContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 350,
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 1,
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 28,
    color: '#8D7A6F',
    marginBottom: 30,
    fontFamily: 'Maharlika',
  },
  question: {
    fontSize: 20,
    display: 'flex',
    textAlign: 'center',
    fontFamily: 'Maharlika',
  },
};

const selectStyles = (type) =>  ({
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #8D7A6F',
    borderRadius: 10,
    height: 50,
    fontFamily: 'Maharlika',
    width: type === 'month' ? 200 : type === 'year' ? 100 : 300,
    cursor: 'pointer',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 50,
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? '#D3D3D3' : 'white',
    color: 'black',
    fontFamily: 'Maharlika',
  }),
});
