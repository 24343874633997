import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { AuthContext } from '../components/AuthContext';

import Colors from '../constants/Colors';
import menu_bar from '../assets/svgs/menu_bar.svg';

export const NAV_HEIGHT = 60;

const MenuItemMobile = ({ title, link, setMenuVisible }) => (
  <Link to={link} className='link' onClick={() => setMenuVisible(false)}>
    <div className='header-item' style={styles.menuItem}>{title}</div>
  </Link>
);

const MenuItem = ({ title, link }) => (
  <Link to={link} className='link'>
    <div className='header-item' style={styles.menuItem}>{title}</div>
  </Link>
);

export default function HeaderNav({ scrollOffset }) {
  const { state } = useContext(AuthContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  if (isMobile) {
    return (
      <div>
        <div className='header-mobile' style={{ backgroundColor: Colors.light.background }}>
          <HashLink to='/#home' className='link' scroll={scrollWithOffset} onClick={() => setMenuVisible(false)}>
            <div style={styles.brandContainer}>
              <div style={styles.brand}>conessi</div>
              <img style={styles.logo} src={require('../assets/images/logo.png')} alt='logo' />
            </div>
          </HashLink>

          <div style={stylesMobile.menuButtonContainer} onClick={() => setMenuVisible(!menuVisible)}>
            <img src={menu_bar} style={stylesMobile.menuIcon} alt='Menu'/>
          </div>
        </div>

        {menuVisible && <div>
          <div className='header-menu' style={{ backgroundColor: Colors.light.background }}>
            <MenuItemMobile title='Home' link='/' setMenuVisible={setMenuVisible} />

            {(!state.user || state.user.type !== 'landlord') && (
              <MenuItemMobile title='Get Matched' link='match' setMenuVisible={setMenuVisible} />
            )}

            <HashLink to='/#about' className='link' scroll={scrollWithOffset} onClick={() => setMenuVisible(false)}>
              <div className='header-item' style={styles.menuItem}>About</div>
            </HashLink>

            {!state.user && <MenuItemMobile title='Login' link='login' setMenuVisible={setMenuVisible} />}
            {state.user && <MenuItemMobile title='My Profile' link={state.user.type} setMenuVisible={setMenuVisible} />}
          </div>

          <div className='header-menu-space' onClick={() => setMenuVisible(false)}>

          </div>
        </div>}
      </div>
    );
  }

  return (
    <div className='header' style={{ backgroundColor: Colors.light.background }}>
      <HashLink to='/#home' scroll={scrollWithOffset} className='link'>
        <div style={styles.brandContainer}>
          <div style={styles.brand}>conessi</div>
          <img style={styles.logo} src={require('../assets/images/logo.png')} alt='logo'/>
        </div>
      </HashLink>
      
      <MenuItem title='Home' link='/' />
      <div style={styles.vr}></div>

      {(!state.user || state.user.type !== 'landlord') && (
        <>
          <MenuItem title='Get Matched' link='match' />
          <div style={styles.vr}></div>
        </>
      )}

      <HashLink to='/#about' scroll={scrollWithOffset} className='link'>
        <div className='header-item' style={styles.menuItem}>About</div>
      </HashLink>
      <div style={styles.vr}></div>

      {!state.user && <MenuItem title='Login' link='login' />}
      {state.user && <MenuItem title='My Profile' link={state.user.type} />}
    </div>
  );
}

const styles = {
  menuItem: {
    fontSize: 16,
  },
  vr: {
    width: 1,
    height: 20,
    backgroundColor: 'black',
  },
  brandContainer: {
    position: 'absolute',
    top: 20,
    left: 24,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  brand: {
    fontSize: 16,
    color: '#46483E',
    fontFamily: 'Maharlika',
  },
  logo: {
    height: 20,
    width: 55,
    objectFit: 'contain',
  },
};

const stylesMobile = {
  menuButtonContainer: {
    height: 36,
  },
  menuIcon: {
    width: 36,
    height: 36,
  },
};
