export default function Billing() {

  return (
    <div style={styles.container}>
      <div>Billing page</div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    padding: 24,
  },
};
