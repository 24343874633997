import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

// export async function action() {
  
// }

// export async function loader() {

// }

export default function Root() {

  return (
    <>
      <div style={{ position: 'fixed', zIndex: 100 }}>
        <HeaderNav />
      </div>
      <div id='content'>
        <Outlet />
      </div>
      <div style={{ zIndex: 100 }}>
        <Footer />
      </div>
    </>
  );
}
