import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Colors from '../constants/Colors';
export const NAV_HEIGHT = 60;

export default function Footer({ scrollOffset }) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (isMobile) {
    return (
      <div className='footer-mobile' style={{ backgroundColor: Colors.light.background, fontFamily: 'Maharlika' }}>

        <Link to='/contact' className='link' style={{ color: 'black' }}>
          Contact Us
        </Link>

        <HashLink to='/#about' smooth className='link' style={{ color: 'black' }}>
          About Us
        </HashLink>

        <Link to='/terms-of-service' className='link' style={{ color: 'black' }}>
          Terms
        </Link>

        <Link to='/privacy-policy' className='link' style={{ color: 'black' }}>
          Privacy
        </Link>
        
        <div style={{ fontSize: 14, marginTop: 4 }}>&copy; Conessi 2023</div>

      </div>
    );
  }

  return (
    <div className='footer' style={{ backgroundColor: Colors.light.background, fontFamily: 'Maharlika' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8, fontSize: 12 }}>
        <Link to='/contact' className='link' style={{ color: 'black' }}>
          Contact Us
        </Link>
        •
        <HashLink to='/#about' smooth className='link' style={{ color: 'black' }}>
          About Us
        </HashLink>
        •
        <Link to='/terms-of-service' className='link' style={{ color: 'black' }}>
          Terms
        </Link>
        •
        <Link to='/privacy-policy' className='link' style={{ color: 'black' }}>
          Privacy
        </Link>
      </div>

      <div>&copy; Conessi 2023</div>
    </div>
  );
}
