import { useEffect, useState } from 'react';
import { Link, useRouteError, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { setItem } from './components/storage';

export default function ErrorPage() {
  const error = useRouteError();
  const location = useLocation();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  // console.error(error);

  useEffect(() => {  
    if (error.message === 'unauthorized') {
      if (location?.pathname) {
        setItem('loginRedirect', location.pathname);

        const type = location.pathname.includes('landlord') ? 'landlord' : 'student';

        navigate({
          pathname: '/login',
          search: createSearchParams({
            type,
          }).toString()
        });
      } else {
        navigate('/login');
      }
    } else setLoaded(true);
  }, []);

  return (
    <>
    {loaded && (
      <div id='error-page'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
    )}
    </>
  );
}
