import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { AuthContext } from '../../components/AuthContext';

import { getMessages, markMessageRead, deleteMessage } from '../../services/landlord';

import open_tab from '../../assets/svgs/open_tab.svg';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export const loader = async ({ params }) => getMessages();

export default function Messages() {
  const { state, authContext } = useContext(AuthContext);
  const messagesData = useLoaderData();

  const [messages, setMessages] = useState(messagesData);
  const [viewingMessage, setView] = useState({});
  const [modalOpen, setOpen] = useState(false);

  const refreshMessages = () => {
    getMessages()
      .then((messages) => {
        setMessages(messages);
      })
      .then(null, (err) => console.log(err));
  }

  const getDate = (date) => new Date(date).toLocaleDateString('en-US', {
    day: '2-digit', month: 'short', year: 'numeric',
  });

  const openProperty = () => {
    window.open(`/property/${viewingMessage.property._id}`, '_blank');
  }

  const emailStudent = () => {
    const { property, student } = viewingMessage;

    const subject = `Property Interest - ${property.name}`;
    const body = `Hello ${student.first_name},\n\nI am following up on your interest in ${property.name}.`;

    window.open(`mailto:${student.email}?subject=${subject}&body=${encodeURIComponent(body)}`);
  }

  const closeMessage = () => {
    setOpen(false);
  }

  const viewMessage = (message) => {
    setView(message);
    setOpen(true);

    markMessageRead(message._id)
      .then((success) => {
        if (success) {
          refreshMessages();
        }
      })
      .then(null, (err) => console.log(err));
  }

  const messageDate = (date) => new Date(date).toLocaleDateString('en-US', {
    day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit'
  });

  const MessageContent = () => {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center', marginBottom: 20 }}>
          <div style={{ fontSize: 18, fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }} onClick={openProperty}>{viewingMessage.property.name}</div>
          <img src={open_tab} style={{ width: 16, height: 16 }} alt='Open in new tab' />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: 12 }}>
          <div style={{ fontWeight: 'bold' }}>Date:</div>
          <div>{messageDate(viewingMessage.date)}</div>

          <div style={{ fontWeight: 'bold' }}>From:</div>
          <div>{viewingMessage.student?.full_name}</div>

          <div style={{ fontWeight: 'bold' }}>Subject:</div>
          <div>Property Interest</div>
        </div>

        <div style={{ marginBottom: 20 }}>
          <div style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>Message:</div>
          <div>{viewingMessage.message}</div>
        </div>

        <hr />

        <div style={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
          <button className='clear-button' onClick={closeMessage}>Close</button>
          <button className='solid-button' onClick={emailStudent}>Reply</button>
        </div>
      </div>
    );
  }

  return (
    <div className='page-container'>
      <div className='info-header'>Messages</div>

      <Popup
        position='center center'
        modal={true}
        closeOnEscape={false}
        contentStyle={{ padding: 16, maxHeight: '90%' }}
        open={modalOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <MessageContent />
      </Popup>

      {messages.length === 0 && (
        <div style={{ fontFamily: 'Maharlika' }}>
          You don't have any messages yet!
        </div>
      )}

      {messages.length > 0 && (
        <table className='message-table'>
          <thead className='message-table-header'>
            <tr>
              <td>Read</td>
              <td>Subject</td>
              <td>From</td>
              <td>Property</td>
              <td>Date</td>
            </tr>
          </thead>
          <tbody className='message-table-body'>
            {messages.map((message, idx) => (
              <tr key={idx} className={message.seen ? '' : 'bold'} onClick={() => viewMessage(message)}>
                <td>{message.seen ? '' : '•'}</td>
                <td>Property Interest</td>
                <td>{message.student?.full_name}</td>
                <td>{message.property.name}</td>
                <td>{getDate(message.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
