import { useContext, useReducer, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Prompt } from '../../components/Prompt';
import { AuthContext } from '../../components/AuthContext';
import { CompanyIcon } from '../../components/CustomImages';
import { updateLandlord } from '../../services/landlord';
import { companyReducer, userReducer } from '../../reducers/landlord';
import { validateEmail } from '../../constants/helpers';
import { countActiveProperties } from '../../services/landlord';

import file_upload from '../../assets/svgs/file_upload.svg';
import info from '../../assets/svgs/info.svg';

export const loader = async ({ params }) => countActiveProperties(params.propertyId);

export default function Settings() {
  const fileInput = useRef(null);
  const { state, authContext } = useContext(AuthContext);
  const numActiveProperties = useLoaderData();

  const [company, dispatchCompany] = useReducer(companyReducer, state.user.landlord);
  const [user, dispatchUser] = useReducer(userReducer, state.user);

  const saveInformation = () => {
    if (company.company_name?.trim()?.length < 3) {
      alert('Please enter a valid company name.');
      return;
    }

    if (!validateEmail(company.email)) {
      alert('Please enter a valid email.');
      return;
    }

    if (company.madeChanges && !user.madeChanges) {
      updateLandlord(company)
        .then((landlord) => {
          dispatchCompany({ type: 'INITIAL_STATE', value: landlord });
          authContext.refreshUser(state);
          alert('Success!');
        })
        .then(null, (err) => console.log(err));
    } else if (!company.madeChanges && user.madeChanges) {
      authContext.updateUser(user, state);
      dispatchUser({ type: 'SAVED_CHANGES' });
      alert('Success!');
    } else {
      updateLandlord(company)
        .then((landlord) => {
          dispatchCompany({ type: 'INITIAL_STATE', value: landlord });
          authContext.updateUser(user, state);
          dispatchUser({ type: 'SAVED_CHANGES' });
          alert('Success!');
        })
        .then(null, (err) => console.log(err));
    }
  }

  const pickImage = async () => {
    fileInput.current.click();
  };

  const handlePhone = (text) => {
    if (!(/^\d+$/.test(text.slice(-1)))) return;
    if (text.length > 10) return;

    dispatchUser({ type: 'PHONE', value: text });
  }

  const handleVerifyEmail = () => {
    window.alert('Your email is not verified. We have resent a verification email.');
    authContext.resendEmailVerification(state);
  }

  const handleImageChange = ({ target }) => {
    const [file] = target.files;

    if (!(/^image\/(png|jpe?g|gif)$/).test(file.type)) return alert('File type not supported');
    if (file.size > 1048576) return alert('Image is too big. Max file size: 1MB.');

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      // convert image file to base64 string
      const uri = reader.result;
      dispatchCompany({ type: 'ICON', value: uri });
    },
      false,
    );

    reader.readAsDataURL(file); 
  }

  return (
    <div className='page-container'>
      <Prompt
        when={company.madeChanges || user.madeChanges}
        message='You may have unsaved changes. Are you sure to discard them and leave this page?'
      />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <div className='info-header'>Account Details</div>
        {(company.madeChanges || user.madeChanges) && (
          <button className='solid-button' onClick={saveInformation}>Save</button>
        )}
      </div>
      <hr />

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Active Listings</div>
        </div>
        <div style={styles.column2}>
          <div>{numActiveProperties}</div>
        </div>
      </div>


      <div className='info-header'>Company Information</div>
      <hr />

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Name</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            className='text-input'
            onChange={({ target }) => dispatchCompany({ type: 'COMPANY_NAME', value: target.value })}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Company Name'
            type='text'
            style={styles.input}
            value={company.company_name || ''}
          />
        </div>
      </div>

      <div className='info-table' style={{ alignItems: 'flex-start' }}>
        <div style={styles.column1}>
          <div style={styles.titleText}>Logo</div>
        </div>
        <div style={styles.column2}>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 24 }} onClick={pickImage}>
            <CompanyIcon image={company.icon} style={styles.icon} />
            <input type='file' onChange={handleImageChange} style={{ display: 'none' }} ref={fileInput} />
            <img src={file_upload} style={{ width: 24, height: 24, cursor: 'pointer' }} alt='Upload' />
          </div>

        </div>
      </div>


      <div className='info-header'>Account Information</div>
      <hr />

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Name</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            className='text-input'
            onChange={({ target }) => dispatchUser({ type: 'NAME', value: target.value })}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Full Name'
            type='text'
            style={styles.input}
            value={user.full_name || ''}
          />
        </div>
      </div>

      <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Email</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            className='text-input'
            disabled
            onChange={({ target }) => dispatchUser({ type: 'EMAIL', value: target.value })}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Account Email'
            type='text'
            style={styles.input}
            value={user.email || ''}
          />
        </div>
        {/* {!state.user.verified_email && <img className='unverified-email' src={info} style={{ width: 24, height: 24 }} alt='Unverified' onClick={handleVerifyEmail} />} */}
      </div>

      {/* <div className='info-table'>
        <div style={styles.column1}>
          <div style={styles.titleText}>Phone</div>
        </div>
        <div style={styles.column2}>
          <input
            autoCapitalize='words'
            autoCorrect='false'
            onChange={({ target }) => handlePhone(target.value)}
            // onSubmitEditing={() => this.passwordInput.focus()}
            placeholder='Account Phone'
            type='text'
            style={styles.input}
            value={user.phone || ''}
          />
        </div>
      </div> */}
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    padding: 24,
  },
  header: {
    fontSize: 20,
    fontWeight: '500',
  },
  table: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  column1: {
    width: 140,
  },
  column2: {
    flex: 1,
    minWidth: 300,
    maxWidth: 500,
  },
  titleText: {
    fontSize: 14,
  },
  hr: {
    borderBottomColor: '#B3735A',
    borderBottomWidth: 2,
    marginTop: 12,
    marginBottom: 24,
  },
  tableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  button: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  buttonAboutUs: {
    backgroundColor: '#CCBFB9',
  },
  buttonAboutUsActive: {
    backgroundColor: '#B5AAA5',
  },
  aboutText: {
    color: '#F4F3F1',
  },
  icon: {
    width: 100,
    height: 100,
    aspectRatio: 1 / 1,
  },
  iconContainer: {
    alignItems: 'center',
    gap: 12,
  },
};
