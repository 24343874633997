import { createContext, useEffect, useMemo, useReducer } from 'react';

import { DefaultLoader } from './Loader';
import { getItem, removeItem, setItem } from './storage';
import Globals from '../constants/Globals';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            user: action.user,
            isLoading: false,
            showWelcome: false,
            errorMessage: '',
            showLogin: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            user: action.user,
            isNewUser: false,
            showWelcome: true,
            showLogin: false,
          };
        case 'SIGN_UP':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            user: action.user,
            isNewUser: true,
            showWelcome: true,
            showLogin: false,
          }
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
            errorMessage: '',
            showLogin: false,
          }
        case 'UPDATE_USER':
          return {
            ...prevState,
            userToken: action.token,
            user: action.user,
            showLogin: false,
          }
        case 'REFRESH_USER':
          return {
            ...prevState,
            user: action.user,
            showLogin: false,
          }
        case 'AUTH_ERR':
          return {
            ...prevState,
            errorMessage: action.error,
            showLogin: false,
          }
        case 'SHOW_LOGIN':
          return {
            ...prevState,
            showLogin: true,
            accountType: action.account_type,
          }
        case 'HIDE_LOGIN':
          return {
            ...prevState,
            errorMessage: '',
            showLogin: false,
            accountType: null,
          }
          default:
            return prevState;
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        userToken = await getItem('userToken');
      } catch (e) {
        // Restoring token failed
      }

      // After restoring token, we may need to validate it in production apps

      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.

      if (userToken === null) {
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      } else {
        fetch(`${Globals.ROOT_URL}/api/auth/validateSession`, {
          headers: {
            'Authorization': userToken,
          },
        })
          .then(res => res.json())
          .then(async (res) => {
            const { user, error } = res;

            if (error) {
              dispatch({ type: 'RESTORE_TOKEN', token: null });
              await removeItem('userToken');
            }
            else
              dispatch({ type: 'RESTORE_TOKEN', token: userToken, user });
          })
          .catch(err => {
            console.log(err);
          });
      }
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        fetch(`${Globals.ROOT_URL}/api/auth/signin`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: data.email,
            password: data.password,
            type: data.type,
          })
        })
          .then(res => res.json())
          .then(async (res) => {
            const { user, token, error } = res;
            if (error) {
              if (error === 'Email or password incorrect.') {
                dispatch({ type: 'AUTH_ERR', error });
              } else {
                dispatch({ type: 'AUTH_ERR', error: 'Account does not exist, please create an account.' });
              }
              return;
            }

            dispatch({ type: 'SIGN_IN', token: `Bearer ${token}`, user });
            await setItem('userToken', `Bearer ${token}`);
          })
          .catch(err => {
            console.log(err);
          });
      },
      signOut: async () => {
        dispatch({ type: 'SIGN_OUT' });
        await removeItem('userToken');
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        fetch(`${Globals.ROOT_URL}/api/auth/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_name: data.company_name,
            full_name: data.full_name,
            email: data.email,
            password: data.password,
            type: data.type,
          })
        })
          .then(res => res.json())
          .then(async (res) => {
            const { user, token, error } = res;
            if (error) {
              dispatch({ type: 'AUTH_ERR', error: 'Account already exists, please login.' });
              return;
            }

            dispatch({ type: 'SIGN_UP', token: `Bearer ${token}`, user });
            await setItem('userToken', `Bearer ${token}`);
          })
          .catch(err => {
            console.log(err);
          });
      },
      updateUser: async (user, state) => {
        fetch(`${Globals.ROOT_URL}/api/auth/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.userToken,
          },
          body: JSON.stringify(user)
        })
          .then(res => res.json())
          .then(async (res) => {
            const { user, token, error } = res;
            if (error) return;

            dispatch({ type: 'UPDATE_USER', token: `Bearer ${token}`, user });
            await setItem('userToken', `Bearer ${token}`);
          })
          .catch(err => {
            console.log(err);
          });
      },
      refreshUser: async (state) => {
        fetch(`${Globals.ROOT_URL}/api/auth/refresh`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.userToken,
          },
        })
          .then(res => res.json())
          .then(async (res) => {
            const { user, error } = res;
            if (error) return;

            dispatch({ type: 'REFRESH_USER', user });
          })
          .catch(err => {
            console.log(err);
          });
      },
      resendEmailVerification: async(state) => {
        fetch(`${Globals.ROOT_URL}/api/auth/resendemailverification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.userToken,
          },
        })
          .then(res => res.json())
          .then(async () => {})
          .catch(err => {
            console.log(err);
          });
      }
    }), []);

  if (state.isLoading) {
    return (
      <div style={styles.container}>
        <DefaultLoader />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ state, authContext }}>
      {children}
    </AuthContext.Provider>
  );
}

const styles = {
  container: {
    padding: 20
  },
  loadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
};

export { AuthContext, AuthProvider };
