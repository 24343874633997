import { getItem } from '../components/storage';
import Globals from '../constants/Globals';

const getAuthToken = () => getItem('userToken');

export const getPropertyStudent = async (propertyId) => {
  return fetch(`${Globals.ROOT_URL}/api/students/property?propertyId=${propertyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.property;
    })
    .then(null, (err) => console.log(err));
}

export const getPropertiesByLandlord = async (landlordId) => {
  return fetch(`${Globals.ROOT_URL}/api/students/properties?landlordId=${landlordId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        landlord: res.landlord,
        properties: res.properties,
      };
    })
    .then(null, (err) => console.log(err));
}

export const updateMatchAnswer = async (key, value) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/matchanswer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

export const createMatchResults = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/creatematches`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

export const sendMessage = async (message, propertyId, landlordId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/sendmessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      message,
      propertyId,
      landlordId,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

export const getMatchedProperties = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/propertymatches`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        best: res.best,
        similar: res.similar,
      };
    })
    .then(null, (err) => console.log(err));
}

export const quizQuestions = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/quiz`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        questions: res.questions,
        finishedQuiz: res.finishedQuiz,
        schools: res.schools,
      };
    })
    .then(null, (err) => console.log(err));
}

export const getActiveSchools = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/students/activeschools`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        schools: res.schools,
      };
    })
    .then(null, (err) => console.log(err));
}
