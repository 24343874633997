export const companyReducer = (prevState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.value;
    case 'ICON':
      return {
        ...prevState,
        icon: action.value,
        madeChanges: true,
      };
    case 'COMPANY_NAME':
      return {
        ...prevState,
        company_name: action.value,
        madeChanges: true,
      };
    case 'EMAIL':
      return {
        ...prevState,
        email: action.value,
        madeChanges: true,
      };
    default:
      return prevState;
  }
};

export const userReducer = (prevState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.property;
    case 'NAME':
      const names = action.value.split(' ');
      const first_name = names[0];
      const last_name = names.pop();
      return {
        ...prevState,
        full_name: action.value,
        first_name,
        last_name,
        madeChanges: true,
      };
    case 'EMAIL':
      return {
        ...prevState,
        email: action.value,
        madeChanges: true,
      };
    case 'PHONE':
      return {
        ...prevState,
        phone: action.value,
        madeChanges: true,
      };
    case 'SAVED_CHANGES':
      return {
        ...prevState,
        madeChanges: false,
      }
    default:
      return prevState;
  }
};
