import { getItem } from '../components/storage';
import { getImageBody } from './upload';
import Globals from '../constants/Globals';

const getAuthToken = () => getItem('userToken');

export const getLandlordInfo = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/landlord`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.landlord;
    })
    .then(null, (err) => console.log(err));
}

export const getLandlordProperties = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.properties;
    })
    .then(null, (err) => console.log(err));
}

export const updateLandlord = async (landlord) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  if (landlord.icon?.includes('base64')) {
    const filename = `${landlord._id}/icon`;
    landlord.icon = await getImageBody(landlord.icon, filename, 150, 150);
  }

  return fetch(`${Globals.ROOT_URL}/api/properties/landlord`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(landlord),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.landlord;
    })
    .then(null, (err) => console.log(err));
}

export const getProperty = async (propertyId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/property?propertyId=${propertyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => ({
      property: res.property,
      schools: res.schools,
      needed: res.dataNeeded,
    }))
    .then(null, (err) => console.log(err));
}

export const updateProperty = async (property) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/property`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(property),
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        property: res.property,
        needed: res.dataNeeded,
      };
    })
    .then(null, (err) => {
      throw new Error({ error: err });
    });
}

export const newProperty = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/property`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.property;
    })
    .then(null, (err) => console.log(err));
}

export const deleteProperty = async (propertyId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/property`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      propertyId,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });

  return uuid;
}

export const uploadPropertyImage = async (property, image, featured) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  const filename = `${property.landlord}/${property._id}/${generateUUID()}`;
  image = await getImageBody(image, filename, 500, 700);

  return fetch(`${Globals.ROOT_URL}/api/properties/uploadPropertyImage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      propertyId: property._id,
      image,
      featured,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.imageUrl;
    })
    .then(null, (err) => console.log(err));
}

export const getMessages = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/getmessages`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.messages;
    })
    .then(null, (err) => console.log(err));
}

export const markMessageRead = async (messageId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/markread`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      messageId,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

export const deleteMessage = async (messageId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/deletemessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      messageId,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.success;
    })
    .then(null, (err) => console.log(err));
}

export const countActiveProperties = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/countactiveproperties`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res.numActiveProperties;
    })
    .then(null, (err) => console.log(err));
}

export const publishProperty = async (propertyId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/publishproperty`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      propertyId,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        success: res.success,
        error: res.error,
      };
    })
    .then(null, (err) => console.log(err));
}

export const unpublishProperty = async (propertyId) => {
  const token = await getAuthToken();
  if (!token) throw new Error('unauthorized');

  return fetch(`${Globals.ROOT_URL}/api/properties/unpublishproperty`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify({
      propertyId,
    }),
  })
    .then((res) => res.json())
    .then((res) => res.success)
    .then(null, (err) => console.log(err));
}
