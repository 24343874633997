import { Link } from 'react-router-dom';

import Colors from '../constants/Colors';

export default function Home() {
  return (
    <div style={styles.container}>

      <div className='section-full-page home-background' id='home'>
        <div style={styles.content}>
          <div className='tagline' style={{ marginBottom: 24, width: '100%', maxWidth: 550, textAlign: 'center' }}>Let us connect you to your ideal space.</div>

          <div style={styles.buttonContainer}>
            <Link to='/match'>
              <button className='clear-button'>get matched</button>
            </Link>
          </div>

        </div>
      </div>

      <div className='section-full-page' id='about'>
        <div style={styles.content2}>
          <div className='tagline'>About Us</div>

          <div style={{ fontFamily: 'Maharlika', color: '#8D7A6F', width: '80%', maxWidth: 750, fontSize: 20 }}>
            Relationships matter. Which is why we connect students with their perfect home. Our strategically designed algorithm finds your perfect match - any size, any budget.
            <br /><br />
            Your perfect match is just a few clicks away. Simply sign up, take our thoughtfully curated quiz, and in minutes you'll be matched with properties that meet your criteria.
            <br /><br />
            {/* <div style={{ cursor: 'pointer' }}> */}
            <Link to='/match' style={{ color: '#8D7A6F' }} className='link'>
              Let's get you <span style={{ textDecoration: 'underline' }}>matched!</span>
            </Link>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div className='section-full-page'>
        <div style={styles.content2}>
          <div className='tagline' style={{ width: '80%', maxWidth: 750 }}>Housing matters. Which{'\n'}is why we offer a{'\n'}seamless way to{'\n'}connect you to your{'\n'}perfect match.</div>
          <Link to='/match'>
            <button className='clear-button'>get matched</button>
          </Link>
        </div>
      </div>

    </div>
  );
}

const styles = {
  container: {
    flex: 1,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: window.innerWidth > 768 ? '50%' : '100%',
    height: '100%',
    flexDirection: 'column',
  },
  content2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    gap: 24,
    textAlign: 'center',
  },
  content4: {
    height: '100%',
    aspectRatio: 1 / 1,
    padding: '5%',
    backgroundColor: Colors.light.background,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  button: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  buttonAboutUs: {
    backgroundColor: '#CCBFB9',
  },
  buttonAboutUsActive: {
    backgroundColor: '#B5AAA5',
  },
  aboutText: {
    color: '#F4F3F1',
  },
  buttonRentals: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  buttonRentalsActive: {
    backgroundColor: '#B3735A',
    borderWidth: 2,
    borderColor: '#B3735A',
  },
  rentalsText: {
    color: '#46483E',
  },
  contactBlurb: {
    fontSize: 18,
    color: '#8D7A6F',
    marginTop: 16,
    marginBottom: 32,
    fontFamily: 'Maharlika',
  }
};
