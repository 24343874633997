import { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const CompanyIcon = (props) => (
  <img {...props} src={props.image || require('../assets/images/icon.png')} style={{ ...props.style, borderRadius: 1000, objectFit: 'cover' }} alt='Landlord Icon' />
);

export const PropertyImage = (props) => (
  <img {...props} src={props.image || require('../assets/images/propertyPlaceholder.png')} style={{ ...props.style, objectFit: 'cover' }} alt='Property' />
);

export function ImageScroller(props) {
  const [index, setIndex] = useState(0);

  const scrollClick = () => {
    setIndex((prevIndex) => prevIndex === props.images.length - 1 ? 0 : prevIndex + 1);
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

      <div className='slideshow-controls slideshow-controls-left' onClick={scrollClick}>
        <div style={{ color: 'white', fontWeight: '600', fontSize: 24, userSelect: 'none' }}>{'<'}</div>
      </div>
      <div className='slideshow-controls slideshow-controls-right' onClick={scrollClick}>
        <div style={{ color: 'white', fontWeight: '600', fontSize: 24, userSelect: 'none' }}>{'>'}</div>
      </div>

      {props.images?.length > 0 && (
        <>
          <div className='slideshow-container'>
            <div className='slideshow-slider'>
              {props.images.map((item, idx) => (
                <img src={item.image} key={idx} style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} alt='Property' className='slideshow-slide' />
              ))}
            </div>
          </div>
          <div style={{ margin: '4px auto 0', maxWidth: '80%', textAlign: 'center' }}>{props.images.at(index).description}</div>
        </>
      )}

      {!props.images?.at(0) && (
        <PropertyImage {...props} image={''} style={props.style} />
      )}
    </div>
  );
}

export function ImageScroller2(props) {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

      {props.images?.length > 0 && (
        <Carousel
          axis='horizontal'
          autoPlay={false}
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          width={'calc(75vh * 5 / 7)'}
        >
          {props.images.map((item, idx) => (
            <div key={idx}>
              <img src={item.image} key={idx} style={{ height: '75vh', objectFit: 'cover' }} alt='Property' />
              <div style={{ margin: '4px auto 0', maxWidth: '80%' }}>{item.description}</div>
            </div>
          ))}
        </Carousel>
      )}

      {!props.images?.at(0) && (
        <PropertyImage {...props} image={''} style={props.style} />
      )}
    </div>
  );
}
