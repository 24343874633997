import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getItem, removeItem } from '../components/storage';
import { AuthContext } from '../components/AuthContext';
import { verifyEmail } from '../services/email';

export default function VerifyEmail() {
  const { state, authContext } = useContext(AuthContext);

  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [token, setToken] = useState(params.get('token') || null);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (token) {
      verifyEmail(token)
        .then((success) => {
          if (success) {
            authContext.refreshUser(state);
          }
        })
        .then(null, (err) => console.log(err));
    }
  }, [token]);

  useEffect(() => {
    if (state.user?.verified_email) {
      setVerified(true);
    } else setTimeout(() => {
        authContext.refreshUser(state);
      }, 1000);
  }, [state]);

  const handleRedirect = () => {
    getItem('loginRedirect')
      .then((redirect) => {
        removeItem('loginRedirect');

        if (redirect) navigate(redirect);
        else {
          if (state.user.type === 'landlord') navigate('/landlord');
          else navigate('/student');
        }
      })
      .then(null, () => {
        if (state.user.type === 'landlord') navigate('/landlord');
        else navigate('/student');
      });
  }

  const resendEmail = () => {
    authContext.resendEmailVerification(state);
    alert('Email sent! Please be sure to check your spam or junk folder.');
  }

  if (verified) {
    return (
      <div className='page-container'>
        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Maharlika', flexDirection: 'column', gap: 40, height: '100%' }}>
          <div style={{ fontSize: 20 }}>
            Your email has been verified!
          </div>
          <button className='solid-button' onClick={handleRedirect}>Continue</button>
        </div>
      </div>
    );
  }

  return (
    <div className='page-container'>
      <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Maharlika', flexDirection: 'column', gap: 40 }}>
        <div>
          Please check your inbox to verify your email!
        </div>
        <div>
          Didn't get email? <span onClick={resendEmail} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Resend</span>
        </div>
      </div>
    </div>
  );
}
